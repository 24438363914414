import { useState } from 'react';
import { useRequest } from 'ahooks';
import { Message } from '@bluevision/components';
import { useTranslation } from 'react-i18next';

import { getExchangeRate } from '@/services/wallet';
import { fetchSuccess } from '@/utils/utils';

export default () => {
  const { t } = useTranslation();

  const [data, setData] = useState<API.ExchangeRate>({});

  const { loading, run } = useRequest(getExchangeRate, {
    manual: true,
    onSuccess(res) {
      if (fetchSuccess(res)) {
        setData(res.data);
      }
    },
    onError() {
      Message.error(t('failed_to_retrieve_exchange_rate'));
      setData({});
    },
  });

  return {
    /** 是否在获取汇率中 */
    loading,
    /** 汇率 */
    rate: {
      /** 人民币充值汇率 */
      usd_to_cny: data.USDCNY,
      /** 拉卡拉-微信汇率 */
      lakala_wechat: data.lakala_wechat,
      /** 拉卡拉-支付宝汇率 */
      lakala_alipay: data.lakala_alipay,
    },
    /** 获取汇率的方法, 它是一个同步方法 */
    getRage: run,
  };
};
