import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { BasicLayoutProps } from '@ant-design/pro-layout';
import type { MenuDataItem } from '@umijs/route-utils';

import MenuCounter from './Counter';
import SideMenu from './index';

type MenuRenderProps = {
  config: BasicLayoutProps;
  dom: React.ReactElement<{
    matchMenuKeys: string[];
    menuData: MenuDataItem[];
  }>;
  /** 收缩宽度，设置为 0 会出现特殊 trigger, 默认为 48 */
  collapsedWidth?: number;
};

const MenuRender: React.FC<MenuRenderProps> = ({
  config,
  dom,
  collapsedWidth = 48,
}) => {
  const { props } = dom;
  const { t, i18n } = useTranslation();

  // 递归地翻译菜单项
  const translateMenuItems = useCallback(
    (items: MenuDataItem[]): MenuDataItem[] =>
      items
        .filter((item) => item && item.name && item.path && !item.hideInMenu)
        .map((item) => ({
          ...item,
          name: t(item.name ?? ''),
          children: item.children ? translateMenuItems(item.children) : [],
        })),
    [t],
  );

  const currentMenuData = useMemo(() => {
    if (props.menuData) {
      return translateMenuItems(props.menuData);
    }
    return [];
  // 这里的 i18n.language 是必须的，每次切换语言都需要重新渲染一次菜单
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translateMenuItems, props.menuData, i18n.language]);

  return (
    // @ts-ignore add TS check, handle history code
    <MenuCounter.Provider>
      <SideMenu
        matchMenuKeys={props.matchMenuKeys}
        {...config}
        menuData={currentMenuData}
        collapsedWidth={collapsedWidth}
      />
    </MenuCounter.Provider>
  );
};

export default MenuRender;
