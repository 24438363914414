// 实名认证结果枚举
export enum IDENTIFICATION_STATUS {
  PASS = 'PASS',
  NOPASS = 'NOPASS',
}

// 支付渠道可验证功能枚举
export enum PAY_CHANNEL_FUNC_FILTER {
  REAL_NAME_IDENTIFICATION = 'real_name_authentication',
}

// 支付渠道可验证功能的验证结果枚举
export enum PAY_CHANNEL_FUNC_STATUS {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

// 用户实名认证状态
export enum USER_IDENTIFY_STATUS {
  UNCERTIFIED = 'UNCERTIFIED',
  CERTIFIED = 'CERTIFIED',
}

// 实名认证默认提示信息
// NOTE: textId
export const DEFAULT_IDENTITY_MESSAGE = {
  FAIL: 'authentication_failed_this_identity_information_has_not_been_authenticated',
  SUCCESS: 'successfully_passed_real_name_authentication',
};
