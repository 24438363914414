import request from '@/utils/request';
import { IPolicy } from '@/pages/Policy/type';
import { API_SMB } from '@/utils/utils';

// const API_SMB = '';

/** 获取资产监控列表 */
export async function getPolicyList(data: IPolicy.Request.PolicyParams) {
  return request(`${API_SMB}/ad_account/page_list`, {
    method: 'POST',
    data,
  });
}

/** 获取当前企业主页监控信息 */
export async function getPageQualityInfo() {
  return request(`${API_SMB}/ad_account/page_quality_info`);
}
