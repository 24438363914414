// @ts-ignore
/* eslint-disable */
// 智齿客服初始化
export const opsigenesInit = () => {
  window['zc'] = function () {
    // @ts-ignore
    window.cbk = window.cbk || [];
    // @ts-ignore
    window.cbk.push(arguments);
  };
  let x = document.createElement('script');
  x.async = true;
  x.id = 'zhichiScript';
  x.className = 'zhiCustomBtn'; //该class绑定到自定义按钮上
  x.src =
    'https://luban.soboten.com/chat/frame/v6/entrance.js?sysnum=8f6bc17e6dce4d11ae8d65d2f7330014';
  document.body.appendChild(x);
};
