type StorageType =
  | 'local' // localStorage
  | 'session'; // sessionStorage

/**
 * 本地存储的二次封装，仅支持 localStorage，sessionStorage
 * @param {string} storageType 本地存储类型 local: localStorage session: sessionStorage
 */
class Storages {
  type: Storage;

  constructor(storageType: StorageType) {
    this.type = { local: localStorage, session: sessionStorage }[storageType];
  }

  /**
   * 从本地存储中获取值，已经自动反序列化(parse)
   * @param key key
   */
  get(key: string) {
    const raw = this.type.getItem(key);

    if (raw) {
      try {
        return JSON.parse(raw);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }

    return undefined;
  }

  /**
   * 将值保存在本地存储中，已经自动序列化(stringify)
   * @param key key
   * @param value 需要保存的值
   */
  set(key: string, value: any) {
    this.type.setItem(key, JSON.stringify(value));
  }

  /**
   * 从本地存储中移除
   * @param key key
   */
  remove(key: string) {
    this.type.removeItem(key);
  }
}

/** localStorage */
export const local = new Storages('local');
/** sessionStorage */
export const session = new Storages('session');
