import request from '@/utils/request';
import { API_SMB } from '@/utils/utils';
import { IOpeningRecord } from '@/pages/OpeningRecord/interface';
import { ResponseStructure } from '@/utils/request';

// const API_SMB = '';

/** 获取开户记录列表 */
export async function getEntityList(
  data: IOpeningRecord.Request.GetEntityParams,
) {
  return request<ResponseStructure<IOpeningRecord.Response.EntityList>>(
    `${API_SMB}/entity/get_entity_list`,
    {
      method: 'POST',
      data,
    },
  );
}

/** Manager 成员信息 - BC/BM下成员列表 */
export async function getManagerMemberList(
  data: IOpeningRecord.Request.GetManagerMemberParams,
) {
  return request<
    ResponseStructure<IOpeningRecord.Response.ManagerMemberRecord>
  >(`${API_SMB}/ad_account/manager_member`, {
    method: 'POST',
    data,
  });
}

/** 提交分配成员 */
export async function submitAccountAssignUser(
  data: IOpeningRecord.Request.SubmitAccountAssignUserParams,
) {
  return request<
    ResponseStructure<IOpeningRecord.Response.ManagerMemberRecord>
  >(`${API_SMB}/ad_account/account_assign_user`, {
    method: 'POST',
    data,
  });
}

/** 获取开户详情 */
export async function getEntityInfo(
  data: IOpeningRecord.Request.GetEntityInfoParams,
) {
  return request<ResponseStructure<IOpeningRecord.Response.EntityInfo>>(
    `${API_SMB}/entity/get_entity_info`,
    {
      method: 'POST',
      data,
    },
  );
}

/** Snapchat重新开户发送邀请
 *  应用场景：snapchat开户详情中，添加成员操作列，成员状态为邀请失效状态，需要重新发送邀请
 */
export async function inviteMember(
  data: IOpeningRecord.Request.InviteMemberParams,
) {
  return request(`${API_SMB}/entity/member_invite`, {
    method: 'POST',
    data,
  });
}

/** 获取Snapchat开户邮箱邀请列表
 *  应用场景：snapchat开户详情中，添加成员模块
 */
export async function getSnapchatMemberList(
  data: IOpeningRecord.Request.GetSnapchatMemberListParams,
) {
  return request<
    ResponseStructure<IOpeningRecord.Response.DetailsSnapchatInfoItem[]>
  >(`${API_SMB}/entity/snap_member_email_list`, {
    method: 'POST',
    data,
  });
}
