import { useState } from 'react';
import { useRequest } from 'ahooks';
import { AccountListModule } from '@/pages/AccountList/interface';
import { getMediaAccountsInfo } from '@/services/accountList';
import { errorMessage } from '@/utils/config';
import { fetchSuccess } from '@/utils/utils';

const defaultBalance: AccountListModule.Response.GetMediaAccountsInfo = {
  META_balance: '0',
  TIKTOK_balance: '0',
  GOOGLE_balance: '0',
  SNAPCHAT_balance: '0',
};

export default () => {
  const [info, setInfo] =
    useState<AccountListModule.Response.GetMediaAccountsInfo>({
      META_balance: '',
      TIKTOK_balance: '',
      GOOGLE_balance: '',
      SNAPCHAT_balance: '',
    });

  const { loading, run: getMediaInfo } = useRequest(getMediaAccountsInfo, {
    manual: true,
    onSuccess(res) {
      if (fetchSuccess(res)) {
        setInfo(res.data);
      } else {
        setInfo(defaultBalance);
        errorMessage(res.info);
      }
    },
    onError() {
      setInfo(defaultBalance);
    },
  });

  return {
    loading,
    info,
    getMediaInfo,
  };
};
