// @ts-ignore add TS check, handle history code
import { RootModal, RootModalProps } from '@smb_mono/components';
import classNames from 'classnames';
import { IconFont } from '@/utils/utils';
import styles from './index.less';
import { useTranslation } from 'react-i18next';

const XModal: React.FC<RootModalProps> = (props) => {
  const { t } = useTranslation();
  const { className, titleClassName, titleStyle, hideFooterBorder, cancelText, ...reset } = props;

  return (
    <RootModal
      className={classNames(styles.xModal, className, {
        [styles['hide-footer-after']]: hideFooterBorder,
      })}
      titleClassName={classNames(
        titleClassName,
        styles.title,
        'fz-20',
        'f-w-5',
      )}
      titleStyle={titleStyle}
      closeIcon={<IconFont type="icon-guanbi" />}
      closeIconClassName={classNames('c-p fz-24', styles.icon)}
      cancelText={cancelText ?? t('cancel')}
      {...reset}
    >
      {props.children}
    </RootModal>
  );
};

export default XModal;
