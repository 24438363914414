import { Space, Switch, Tooltip } from 'antd';
import styles from './remindSwitchFormItem.less';
import { IconFont } from '@/utils/utils';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface FormItemProps {
  value?: boolean;
  onChange?: (checked: boolean) => void;
}

const RemindSwitchFormItem: React.FC<FormItemProps> = ({ value, onChange }) => {
  const { t } = useTranslation();

  return <div className={styles['remind-content']}>
    <div className='fx-h-b-v-c'>
      <Space align='center' size={4}>
        <div className={classNames('fz-12', styles.notice)}>{t('sms_notification_service')}</div>
        <Tooltip title={t('including_reminders_such_as_account_opening_rejection_recharge_account_balance_warning_and_account_suspension')}>
          <IconFont type='icon-tanhao' className={classNames('fz-14', styles.icon)} />
        </Tooltip>
      </Space>
      <Switch onChange={onChange} checked={value} size='small' />
    </div>
  </div>;
};

export default RemindSwitchFormItem;
