import { uniqBy } from 'lodash';
import { getDvaApp, history } from '@umijs/max';
import AuthorityModal from '@/components/AuthorityModal';
import { AllPlatform } from '@/utils/enum';
import { AuthorityModule } from '@/pages/Authority/interface';
import { ResponseStructure } from '@/utils/request';
import { errorMessage } from '@/utils/config';
// @ts-ignore add TS check, handle history code
import * as Sentry from '@sentry/browser';
import { AccountListModule } from '@/pages/AccountList/interface';
import { IMediaApply } from './type';
import i18n from '@/i18n';

/** 处理Tiktok表单的全量拉取授权信息
 *  @params dataSources 数据源
 *  @return userDataSources:去重后的user全量信息
 *          managerDataSources:去重后的manager全量信息
 *          userMap:以user_id为key，去搜索包含user_id的manager信息
 *          managerMap:以manager_id为key，去搜索包含manager_id的user信息
 */
export const getAuthorityInfo = (
  dataSources: AccountListModule.Response.MediaManageItem[],
) => {
  const userDataSources: IMediaApply.User = [];

  const managerDataSources: IMediaApply.Manager = [];

  const userMap = new Map<string, IMediaApply.Manager>();

  const managerMap = new Map<string, IMediaApply.User>();

  dataSources.forEach(({ user_id, user_name, manager_id, manager_name }) => {
    /** 存储user全量信息 */
    userDataSources.push({ user_id, user_name });

    /** 存储manager全量信息 */
    managerDataSources.push({ manager_id, manager_name });

    /** 存储以user_id为key，[{manager_id,manager_name}]为value的map数据结构 */
    const savedUser = userMap.get(user_id) || [];

    userMap.set(
      user_id,
      uniqBy([...savedUser, { manager_id, manager_name }], 'manager_id'),
    );

    /** 存储以manager_id为key，[{user_id,user_name}]为value的map数据结构 */
    const savedManager = managerMap.get(manager_id) || [];

    managerMap.set(
      manager_id,
      uniqBy([...savedManager, { user_id, user_name }], 'user_id'),
    );
  });

  return {
    userDataSources: uniqBy(userDataSources, 'user_id'),
    managerDataSources: uniqBy(managerDataSources, 'manager_id'),
    userMap,
    managerMap,
  };
};

/** 授权列表返回的参数 */
export type AuthListRes =
  ResponseStructure<AuthorityModule.Response.GetAuthList>;

/** 授权链接返回的参数 */
type AuthLinkRes = ResponseStructure<AuthorityModule.Response.GetAuthLink>;

/** 媒体映射用于渲染授权弹窗标题 */
const platformMap = {
  [AllPlatform.META]: 'Facebook',
  [AllPlatform.TIKTOK]: 'Tiktok',
  [AllPlatform.GOOGLE]: 'Google',
};

export const getDvaConfig = () => {
  const methodCollection = getDvaApp()?._store;

  const getState = methodCollection?.getState;
  const loading = methodCollection?.getState()?.loading;
  const dispatch = methodCollection?.dispatch;

  return {
    loading,
    dispatch,
    getState,
  };
};

/** 前往授权 */
export const goToAuthority = ({
  platform,
  /** 不传入参数，点击取消按钮，默认跳转至开户申请界面 */
  onCancel = () => {
    history.push('/opening-account/outline');
  },
}: {
  platform: AllPlatform;
  onCancel?: () => void;
}) => {
  const { t } = i18n;
  const { dispatch, loading } = getDvaConfig();

  const media = platformMap[platform];

  AuthorityModal.confirm({
    // title: `申请${media}授权`,
    // content: `完成${media}授权后即可开户`,
    title: t('applying_for_authorization', { var: media }),
    content: t('after_completing_authorization_you_can_open_an_account', { var: media }),
    onOk: (cancel) => {
      dispatch({
        type: 'authority/getAuthLink',
        payload: {
          platform,
          path: window.location.origin + '/authority',
        },
        requestSuccess: (res: AuthLinkRes) => {
          cancel();
          location.replace(res.data.oauth_url);
        },
        requestFail: (res: AuthLinkRes) => {
          errorMessage(res.info);
          Sentry?.captureException(res.info);
        },
      });
    },
    okButtonProps: {
      loading: loading.effects['authority/getAuthLink'] || false,
    },
    onCancel,
  });
};

/** 除Meta、Snapchat媒体外，媒体开户都需要先获取当前的授权信息
 *  授权信息存在，则跳转至开户表单
 *  授权信息不存在，则获取授权链接地址，进行授权
 */
export const goToOpeningAccount = ({
  platform,
}: {
  /** 媒体类型 */
  platform: AllPlatform;
}) => {
  const { dispatch } = getDvaConfig();

  dispatch({
    type: 'authority/getAuthList',
    payload: {
      platform,
    },
    requestSuccess: (res: AuthListRes) => {
      /** 当前无授权的情况下 */
      if (res.data.records.length === 0) {
        goToAuthority({ platform });
        return;
      }
    },
    requestFail: (res: AuthListRes) => {
      errorMessage(res.info);
      Sentry?.captureException(res.info);
    },
  });
};
