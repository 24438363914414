import { Badge } from 'antd';
import classNames from 'classnames';
import styles from './index.less';

export type CustomBadgeStatus =
  | 'success'
  | 'error'
  | 'warning'
  | 'default'
  | 'processing';

type Props = {
  status: CustomBadgeStatus;
  className?: string;
};

const CustomBadge = (props: Props) => {
  const cls = classNames(props.className, styles.badge, {
    [styles.success]: props.status === 'success',
    [styles.error]: props.status === 'error',
    [styles.warning]: props.status === 'warning',
    [styles.default]: props.status === 'default',
    [styles.processing]: props.status === 'processing',
  });

  return <Badge {...props} className={cls} />;
};

export default CustomBadge;
