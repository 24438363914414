// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';
import { useModel } from '@@/plugin-model';
import Loading from '@ant-design/pro-layout/es/PageLoading'
export default function InitialStateProvider(props: any) {
  const appLoaded = React.useRef(false);
  const { loading = false } = useModel("@@initialState") || {};
  React.useEffect(() => {
    if (!loading) {
      appLoaded.current = true;
    }
  }, [loading]);
  if (loading && !appLoaded.current) {
    return <Loading />;
  }
  return props.children;
}
