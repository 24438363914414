/** 禁止使用浏览器相关API，文件会被config引用，config会在node环境运行，所以不支持浏览器相关API */
import { notification } from 'antd';
import { isString, isNumber } from 'lodash';
import moment from 'moment';

export const defaultError = '未知错误';

export const CDN_URL = 'https://bluevs-static.domobcdn.com/';

/** 空文本显示符 */
export const EmptyText = '--';

/** 错误提示 */
export function errorNotice(msg?: string, desc?: string, duration?: number) {
  notification.error({
    message: msg || defaultError,
    description: desc,
    duration,
  });
}

/** RangePicker 禁用日期 */
export const disabledDate = (current: moment.Moment) =>
  current ? current > moment().add(1, 'days').startOf('day') : false;

/** 近七天
 *  @description:不用用这个了，它在组件中使用不刷新，会导致时间只更新一次
 */
export const nearlySevenDay = [moment().add(-7, 'days'), moment()];

export const getNearlySevenDay = () => {
  return [moment().add(-7, 'days'), moment()];
};

/** 美元换算成人民币 */
export const USDTOCNY = (amount?: number, rate?: string) =>
  (+(amount || 0) * 100 * (+(rate || 0) * 1000000)) / 100000000 || 0;

/** 去除字符串两边的空格 */
export const trimSpace = (params: { target: { value: string } } | string) => {
  // 如果 params 为一个对象，这个对象是一个事件对象
  if (typeof params === 'object' && 'target' in params) {
    const { value = '' } = params.target;

    return value.trim();
  }

  // 否则 params 是一个字符串
  return (params ?? '').trim();
};

/** 模拟延迟 */
export const delay = (time: number) =>
  // eslint-disable-next-line no-promise-executor-return
  new Promise((resolve) => setTimeout(resolve, time));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function currency(s: any, type = '') {
  if (Number.isNaN(s) || s === undefined || s === null) {
    return '- -';
  }
  // eslint-disable-next-line no-useless-escape
  const value = `${parseFloat(`${s}`.replace(/[^\d\.-]/g, '')).toFixed(2)}`;
  const l = value.split('.')[0].split('').reverse();
  const r = value.split('.')[1];
  let t = '';
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? ',' : '');
  }
  return `${type + t.split('').reverse().join('')}.${r}`;
}

/** InputNumber框数据格式化 保留两位小数 */
export const limitDecimals = (value = ''): string => {
  // eslint-disable-next-line no-useless-escape
  const reg = /^(\-)*(\d+)\.(\d\d).*$/;
  if (typeof value === 'string') {
    return !isNaN(Number(value)) ? value.replace(reg, '$1$2.$3') : '';
  } else if (typeof value === 'number') {
    return !isNaN(value) ? String(value).replace(reg, '$1$2.$3') : '';
  } else {
    return '';
  }
};

export const noop = () => {};

/** 关于数字总量的切割方法 每三位切割填充逗号 */
export const totalCurrency = (params: number | string, type = '') => {
  if (!isString(params) && !isNumber(params)) return 0;

  const s = String(params);

  // eslint-disable-next-line no-useless-escape
  const value = `${parseFloat(`${s}`.replace(/[^\d\.-]/g, ''))}`;
  let t = '';
  for (let i = 0; i < value.length; i++) {
    t += value[i] + ((i + 1) % 3 === 0 && i + 1 !== value.length ? ',' : '');
  }
  return `${type + t.split('').join('')}`;
};
