// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';
import { ConfigProvider, Modal, message, notification } from 'antd';

export function rootContainer(container) {
  const finalConfig = {...{"autoInsertSpaceInButton":false}}
  if (finalConfig.prefixCls) {
    Modal.config({
      rootPrefixCls: finalConfig.prefixCls
    });
    message.config({
      prefixCls: `${finalConfig.prefixCls}-message`
    });
    notification.config({
      prefixCls: `${finalConfig.prefixCls}-notification`
    });
  }
  return <ConfigProvider {...finalConfig}>{container}</ConfigProvider>;
}
