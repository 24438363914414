import {
  isMobile,
  isAndroid,
  isIOS,
  isIOS13,
  isIPad13,
  isIPhone13,
  isIPod13,
  isWinPhone,
  isTablet,
  isMobileSafari,
} from 'react-device-detect';

/** 判断是否是移动端 */
export function checkIsMobile() {
  return (
    isMobile ||
    isAndroid ||
    isIOS ||
    isWinPhone ||
    isTablet ||
    isMobileSafari ||
    isIOS13 ||
    isIPad13 ||
    isIPhone13 ||
    isIPod13
  );
}

/** 是否为生产环境 */
export const isProdDomain = () => {
  return location.host.endsWith('.bluemediagroup.cn');
};