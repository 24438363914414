import { getAuthLink, authSwitch, getAuthList } from '@/services/authority';
// @ts-ignore add TS check, handle history code
import { SagaReturnType } from 'redux-saga/effects';
import { fetchSuccess } from '@/utils/utils';

// eslint-disable-next-line @typescript-eslint/ban-types
export type State = {};

export type AuthorityModelType = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  state: State;
  namespace: 'authority';
  effects: {
    getAuthLink: Effect;
    authSwitch: Effect;
    getAuthList: Effect;
  };
};

const AuthorityModel: AuthorityModelType = {
  state: {},
  namespace: 'authority',
  effects: {
    /** 获取全部媒体授权信息 */
    *getAuthList({ payload, requestSuccess, requestFail }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof getAuthList> = yield call(
        getAuthList,
        payload,
      );

      if (fetchSuccess(res)) {
        return requestSuccess?.(res);
      }
      requestFail?.(res);
    },
    /** 获取媒体授权链接 */
    *getAuthLink({ payload, requestSuccess, requestFail }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof getAuthLink> = yield call(
        getAuthLink,
        payload,
      );
      if (fetchSuccess(res)) {
        return requestSuccess?.(res);
      }
      requestFail?.(res);
    },
    /** 切换授权 */
    *authSwitch({ payload, requestSuccess, requestFail }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof authSwitch> = yield call(
        authSwitch,
        payload,
      );
      if (fetchSuccess(res)) {
        return requestSuccess?.(res);
      }
      requestFail?.(res);
    },
  },
};

export default AuthorityModel;
