import request, { ResponseStructure } from '@/utils/request';
import { API_SMB } from '@/utils/utils';

import {
  RealNameIdentificationContent,
  SubmitRealNameIdentification,
  SubmitRealNameIdentificationResponse,
  PayChannelInfoRequest,
  PayChannelInfoResponse,
} from '@/interface/realNameIdentification';

/** 获取实名认证信息 */
export async function getIdentificationInfo() {
  return request<ResponseStructure<RealNameIdentificationContent[]>>(
    `${API_SMB}/user/realname_a`,
    { method: 'GET' },
  );
}

/** 提交实名认证信息 */
export async function submitIdentificationInfo(data: SubmitRealNameIdentification) {
  return request<ResponseStructure<SubmitRealNameIdentificationResponse>>(
    `${API_SMB}/user/realname_a`,
    {
      method: 'POST',
      data,
    },
  );
}

/** 根据支付渠道判断是否需要实名认证 */
export async function getPayChannelStatus(data: PayChannelInfoRequest) {
  return request<ResponseStructure<PayChannelInfoResponse>>(
    `${API_SMB}/capital/recharge_switch_filter`,
    {
      method: 'POST',
      data,
    },
  );
}
