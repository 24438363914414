type AgreeState = {
  is_agree: boolean;
}

export interface AgreeProtocolType {
  namespace: 'agreeProtocol';
  state: AgreeState;
  reducers: {
    saveState: Reducer<AgreeState>;
  };
}
const defaultState: AgreeState = {
  is_agree: true,
};

const AgreeProtocol: AgreeProtocolType = {
  namespace: 'agreeProtocol',
  state: defaultState,
  reducers: {
    saveState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default AgreeProtocol;
