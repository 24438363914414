import { memo } from 'react';
import { Button } from 'antd';
import { history, useModel } from '@umijs/max';
import classNames from 'classnames';
import { useRequest } from 'ahooks';
import XModal from '@/components/XModal';
import { IconFont, fetchSuccess } from '@/utils/utils';
import { errorMessage } from '@/utils/config';
import { logout } from '@/services/user';
import { removeUserRole, removeUsername } from '@/tracks';
import styles from './index.less';
import { useTranslation } from 'react-i18next';

type LogoutProps = {
  visible: boolean;
  onCancel: () => void;
};

const Logout: React.FC<LogoutProps> = ({ visible, onCancel }) => {
  const { setInitialState } = useModel('@@initialState');
  const { t } = useTranslation();
  const { loading, runAsync } = useRequest(logout, { manual: true });

  const onConfirm = async () => {
    try {
      const res = await runAsync();

      if (fetchSuccess(res)) {
        await setInitialState((s) => ({
          ...s,
          currentUser: undefined,
          businessInfo: undefined,
        }));
        removeUsername();
        removeUserRole();
        if (['/', '/index', '/partner', '/about', '/guide'].includes(location.pathname)) return;
        history.replace('/user/login');
      }

      if (!fetchSuccess(res)) {
        errorMessage(t('login_failed_please_try_again'));
      }
    } catch (e) {}
  };

  return (
    <XModal
      open={visible}
      title={
        <div className={styles.header}>
          <IconFont
            type="icon-tuichu"
            className={classNames('fz-20', styles.logoutIcon)}
          />
          <span>{t('are_you_sure_to_exit_luban_plus')}</span>
        </div>
      }
      closable={false}
      centered
      footer={null}
      maskClosable={false}
      className={styles.confirmModal}
    >
      <div className={styles.desc}>
        {t('after_confirming_the_exit_you_need_to_log_in_again_before_you_can_enter_luban_plus_again')}
      </div>
      <div className={styles.btns}>
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <Button
          type="primary"
          onClick={onConfirm}
          loading={loading}
          disabled={loading}
        >
          {t('confirm')}
        </Button>
      </div>
    </XModal>
  );
};

export default memo(Logout);
