import { memo, useMemo } from 'react';
import classNames from 'classnames';
import { useRequest, useUnmount } from 'ahooks';
import { useModel } from '@umijs/max';
import { Form, Input, Space } from 'antd';
import XModal from '@/components/XModal';
import { updateUserInfo } from '@/services/user';
import { IconFont, fetchSuccess } from '@/utils/utils';
// @ts-ignore add TS check, handle history code
import { trimSpace } from '@smb_mono/utils';
import { successMessage, errorMessage } from '@/utils/config';
import styles from './index.less';
import { useTranslation } from 'react-i18next';
import { BoolEnum } from '@/utils/enum';
import RemindSwitchFormItem from './cellRender/RemindSwitchFormItem';
import SmsCodeFormItem from './cellRender/SmsCodeFormItem';

type UserInfoProps = {
  visible: boolean;
  onCancel: () => void;
};

const UserInfo: React.FC<UserInfoProps> = ({ visible, onCancel }) => {
  const { t } = useTranslation();
  const { initialState, setInitialState } = useModel('@@initialState');
  const { loading, runAsync } = useRequest(updateUserInfo, { manual: true });
  const [form] = Form.useForm<API.UserInfo>();
  // 是否开启短信提醒 初始值 true / false
  const defaultRemind = useMemo(() => initialState?.currentUser?.is_remind === BoolEnum.YES, [initialState?.currentUser]);

  const reset = () => form.resetFields();

  useUnmount(reset);

  const handleSubmit = async () => {
    try {
      const value = await form.validateFields();

      const res = await runAsync({
        ...value,
        is_remind: value.is_remind ? BoolEnum.YES : BoolEnum.NO,
      });

      if (fetchSuccess(res)) {
        successMessage(t('modified_successfully'));
        onCancel();
        setInitialState((state) => ({ ...state, currentUser: res.data }));
      }

      if (!fetchSuccess(res)) {
        errorMessage(t('modification_failed_please_try_again'));
      }
    } catch (e) { }
  };

  return (
    <XModal
      title={t('my_information')}
      open={visible}
      onCancel={onCancel}
      centered
      okText={t('save_and_modify')}
      onOk={handleSubmit}
      okButtonProps={{ loading, disabled: loading }}
      maskClosable={false}
      destroyOnClose
      afterClose={reset}
    >
      <Form
        layout="vertical"
        className={classNames('userForm', styles.userInfo)}
        form={form}
      >
        <Form.Item
          initialValue={initialState?.currentUser?.nickname}
          label={
            <div className={classNames("fx-h-b", styles.username)}>
              <span>{t('user_name')}</span>
              {initialState?.currentUser?.is_business_member === BoolEnum.YES && <Space size={5}>
                <IconFont type="icon-tanhao" />
                {t('this_username_is_visible_to_enterprise_members')}
              </Space>}
            </div>
          }
          name="nickname"
          rules={[
            { required: true, message: t('please_enter_the_user_name') },
            {
              validator(_, value) {
                const length = value?.replace(/[^\x00-\xff]/g, '01')?.length;

                if (length > 10) {
                  return Promise.reject(
                    new Error(t('the_input_content_cannot_exceed_5_chinese_characters10_english_characters')),
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
          getValueFromEvent={trimSpace}
        >
          <Input placeholder={t('please_enter_the_user_name')} />
        </Form.Item>
        <Form.Item
          initialValue={initialState?.currentUser?.contact_number}
          label={t('mobile_phone_number')}
          name="contact_number"
          getValueFromEvent={trimSpace}
          rules={[
            { required: true, message: t('please_enter_a_valid_contact_information') }
          ]}
        >
          <Input placeholder={t('please_enter_a_valid_contact_information')} />
        </Form.Item>
        <Form.Item
          initialValue={defaultRemind}
          name="is_remind"
        >
          <RemindSwitchFormItem />
        </Form.Item>
        <Form.Item dependencies={['is_remind']} noStyle>
          {({ getFieldValue }) => {
            const is_remind = getFieldValue('is_remind') || false;
            const required = is_remind;
            return required ? <Form.Item
              name="captcha_code"
              rules={[
                { required, message: t('please_enter_the_verification_code') }
              ]}
            >
              <SmsCodeFormItem form={form} code_type='remind' dependency='contact_number' className={styles['sms-code']} />
            </Form.Item> : null;
          }}
        </Form.Item>
        <Form.Item
          initialValue={initialState?.currentUser?.identity_card}
          label={t('id_number')}
          name="identity_card"
          getValueFromEvent={trimSpace}
        >
          <Input placeholder={t('please_enter_the_responsible_persons_id_number_number')} />
        </Form.Item>
        <Form.Item
          initialValue={initialState?.currentUser?.card_number}
          label={t('bank_card_number')}
          name="card_number"
          getValueFromEvent={trimSpace}
        >
          <Input placeholder={t('please_enter_your_bank_card_number')} />
        </Form.Item>
      </Form>
    </XModal>
  );
};

export default memo(UserInfo);
