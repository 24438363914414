import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { CSSProperties } from 'react';
import classNames from 'classnames';
import type { ModalProps } from 'antd';
import styles from './index.less';

export type RootModalProps = {
  children?: React.ReactNode;
  /** 头部类名 */
  titleClassName?: string;
  /** 头部 style */
  titleStyle?: CSSProperties;
  /** 默认关闭Icon的样式 */
  closeIconClassName?: string;
  closeIconStyle?: CSSProperties;

  /** 是否隐藏footer border */
  hideFooterBorder?: boolean;
} & ModalProps;

const RootModal: React.FC<RootModalProps> = (props) => {
  const {
    title,
    titleClassName,
    titleStyle,
    closeIconClassName,
    closeIconStyle,
    closable: shouldShowCloseIcon = true,
    closeIcon,
    ...reset
  } = props;

  const Close = (
    <div
      className={classNames(styles.close, closeIconClassName)}
      style={closeIconStyle}
      onClick={props?.onCancel}
    >
      {closeIcon || <CloseOutlined />}
    </div>
  );

  const header = () => {
    if (title) {
      return (
        <section
          className={classNames(styles.header, titleClassName)}
          style={titleStyle}
        >
          <span>{title}</span>
          {shouldShowCloseIcon && Close}
        </section>
      );
    }
    return (
      shouldShowCloseIcon && (
        <section className={styles['only-close']}>{Close}</section>
      )
    );
  };

  return (
    <Modal centered {...reset} title={header()} closable={false}>
      {props.children}
    </Modal>
  );
};

export default RootModal;
