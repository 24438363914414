import { SagaReturnType } from 'redux-saga/effects';

import { GrayInfo } from '@/interface/gray';
import { getGrayInfo } from '@/services/grayInfo';

export type GrayState = GrayInfo;

export interface GrayModelType {
  namespace: 'grayInfo';
  state: GrayState;
  effects: {
    getGrayInfo: Effect;
  };
  reducers: {
    saveState: Reducer<GrayState>;
  };
}

export const defaultState: GrayState = {
  real_name_authentication: false,
};

const GrayModel: GrayModelType = {
  namespace: 'grayInfo',
  state: defaultState,
  reducers: {
    saveState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *getGrayInfo(_, { call, put }) {
      const res: SagaReturnType<typeof getGrayInfo> = yield call(getGrayInfo);

      put({
        type: 'saveState',
        payload: res.data,
      });
    },
  },
};

export default GrayModel;
