import React from 'react';
import classNames from 'classnames';
import styles from './index.less';
import useTranslationCls from '@/hooks/useTranslationCls';
import { qrcode } from '@/utils/constant';

interface IBoxProps {
  imgSrc: string;
  title: string;
  desc: string;
  contentImg: string;
  content?: string;
  onMouseOver?: () => void;
}

export const Box: React.FC<IBoxProps> = ({
  imgSrc,
  title,
  desc,
  contentImg,
  content,
}) => {
  const cls = useTranslationCls();

  return (
    <div className={classNames(styles.boxWrap, 'c-p')}>
      <div className={styles.bg}>
        <img src={imgSrc} alt="" className={classNames(styles.icon)} />
      </div>
      <div className={classNames(styles.base, styles.box)}>
        <div className={classNames(styles.boxTitle, cls)}>
          <span>
            <img src={contentImg} alt="" className={styles.boxTitleIcon} />
          </span>
          <span className={styles.boxTitleText}>{title}</span>
        </div>
        <div className={styles.boxDesc}>{desc}</div>
        {content ? (
          <div className={styles.lineContent}>{content}</div>
        ) : (
          <div className={styles.boxContent}>
            <img className={styles.boxContentImg} src={qrcode} alt="" />
          </div>
        )}
      </div>
    </div>
  );
}
