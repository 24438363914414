import { Modal } from 'antd';
import { IconFont } from '@/utils/utils';
import classNames from 'classnames';
import { Confirm } from './type';
import styles from './index.less';
import { useTranslation } from 'react-i18next';
import i18n from '@/i18n';

const confirm = ({
  title,
  content,
  okText = 'go_to_authorization',
  onOk,
  okButtonProps,
  onCancel,
}: Confirm) => {
  const {t} = i18n;
  Modal.confirm({
    title: (
      <div className={classNames(styles.header, 'fx-v-c')}>
        <div className={classNames('fx-h-v-c', styles.mask)}>
          <IconFont
            type="icon-shenhe"
            className={classNames(styles.icon, 'fz-20')}
          />
        </div>
        <span className={classNames('fz-20', 'f-w-5')}>{title}</span>
      </div>
    ),
    content: <div className={styles.content}>{content}</div>,
    icon: null,
    className: styles.modal,
    width: 416,
    centered: true,
    okText: t(okText),
    onOk,
    okButtonProps,
    onCancel,
  });
};

const AuthorityModal = () => {
  const { t } = useTranslation();
  return t('temporarily_unavailable_please_use_the_authoritymodal_confirm_method_to_raise_a_popup_window');
};

AuthorityModal.confirm = confirm;

export default AuthorityModal;
