import { Layout, Tooltip } from 'antd';
import classNames from 'classnames';
import { IconFont } from '@/utils/utils';
import type { SiderProps } from 'antd/lib/layout/Sider';
import type { WithFalse } from './typings';
import type { BaseMenuProps } from './BaseMenu';
import BaseMenu from './BaseMenu';
import MenuCounter from './Counter';
import styles from './index.less';
import { useTranslation } from 'react-i18next';

export type SiderMenuProps = {
  siderWidth?: number;
  /** 收缩宽度，设置为 0 会出现特殊 trigger */
  collapsedWidth?: number;
  /**
   * @name 菜单是否收起的断点，设置成false 可以禁用
   *
   * @example 禁用断点  breakpoint={false}
   * @example 最小的屏幕再收起 breakpoint={"xs"}
   */
  breakpoint?: SiderProps['breakpoint'] | false;
  onOpenChange?: (openKeys: WithFalse<string[]>) => void;
} & Pick<BaseMenuProps, Exclude<keyof BaseMenuProps, ['onCollapse']>>;

export type PrivateSiderMenuProps = {
  matchMenuKeys: string[];
};

const SiderMenu: React.FC<SiderMenuProps & PrivateSiderMenuProps> = (props) => {
  const {
    collapsed,
    fixSiderbar,
    onCollapse,
    theme,
    siderWidth,
    collapsedWidth,
    breakpoint = 'xl',
    layout,
    onOpenChange,
    headerHeight,
  } = props;

  const { flatMenuKeys } = MenuCounter.useContainer();
  const { t } = useTranslation();

  return (
    <>
      {fixSiderbar && (
        <div
          style={{
            width: collapsed ? collapsedWidth : siderWidth,
            overflow: 'hidden',
            flex: `0 0 ${collapsed ? collapsedWidth : siderWidth}px`,
            maxWidth: collapsed ? collapsedWidth : siderWidth,
            minWidth: collapsed ? collapsedWidth : siderWidth,
            transition: `background-color 0.3s, min-width 0.3s, max-width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)`,
          }}
        />
      )}
      <Layout.Sider
        collapsible
        trigger={null}
        collapsed={collapsed}
        breakpoint={breakpoint === false ? undefined : breakpoint}
        onCollapse={(collapse) => {
          onCollapse?.(collapse);
        }}
        collapsedWidth={collapsedWidth}
        style={{
          overflow: 'hidden',
          paddingTop: layout === 'mix' ? headerHeight : undefined,
        }}
        width={siderWidth}
        theme={theme}
        className={classNames(styles.aside, styles['menu-sider-content'], {
          [styles['aside-uncollapsed']]: !collapsed,
        })}
      >
        <div
          style={{
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {flatMenuKeys && (
            <BaseMenu
              {...props}
              key="base-menu"
              mode="inline"
              handleOpenChange={onOpenChange}
              style={{
                width: '100%',
              }}
            />
          )}
        </div>
        <div
          className={classNames(
            styles.menuFooter,
            collapsed ? styles.menuFooterFold : styles.menuFooterExpand,
          )}
          onClick={() => onCollapse?.(!collapsed)}
        >
          {collapsed ? (
            <Tooltip title={t('expand_sidebar')} placement="right">
              <IconFont type="icon-zhankai" className="fz-20" />
            </Tooltip>
          ) : (
            <IconFont type="icon-shouqi" className="fz-20" />
          )}
          {!collapsed && <span className={styles.action}>{t('collapse_the_sidebar')}</span>}
        </div>
      </Layout.Sider>
    </>
  );
};

export default SiderMenu;
