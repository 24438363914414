import { memo } from 'react';
import { Alert } from 'antd';
import { IconFont } from '@/utils/utils';
import styles from './index.less';

type MessageProps = {
  content: React.ReactNode;
  style?: React.CSSProperties;
};

const Message: React.FC<MessageProps> = ({ content, style }) => {
  return (
    <Alert
      style={{
        display: 'inline-flex',
        width: '100%',
        marginBottom: 20,
        marginTop: 4,
        ...style,
      }}
      message={content}
      type="error"
      showIcon
      icon={<IconFont type="icon-cha" />}
      className={styles.alert}
    />
  );
};

export default memo(Message);
