// 注意：这里引入的本地翻译文件名是'_'连接
import enUS from '@/locales/en-US.json';
import zhCN from '@/locales/zh-CN.json';
import { Locales, LOCALES_MAP } from '@/i18n';
import type { Translation } from '@/interface/i18n';
import { getTranslation } from '@/services/i18n';
import * as Sentry from '@sentry/browser';

import i18n from '@/i18n';

/** 转换翻译数据结构 */
export function transformTranslateData(originalData: Translation[]) {
  const localeMap = {};

  Object.values(Locales).forEach((locale) => {
    originalData.forEach((item) => {
      if (!localeMap[locale]) {
        localeMap[locale] = {};
      }
      localeMap[locale][item.text_id] = item[LOCALES_MAP[locale]];
    });
  });

  return localeMap;
}

/** 读取本地Local文件夹下的翻译数据 */
export function readTranslateFile() {
  return {
    [Locales.zhCN]: zhCN,
    [Locales.enUS]: enUS,
  };
}

/** 转换浏览器页签 Title */
export function transformPageTitle(originTitle: string) {
  return i18n.t(originTitle);
}

// Class Component 无法使用 @/hooks/useTranslationResources 所以封装一个普通函数
export function getTranslationResources() {
  getTranslation()
    .then((originLocales) => {
      if (!originLocales || originLocales.length === 0) {
        throw new Error('接口返回数据为空');
      }
      const transferLocale = transformTranslateData(originLocales);
      Object.keys(transferLocale).forEach((lang) => {
        localStorage.setItem(lang, JSON.stringify(transferLocale[lang]));
        i18n.addResourceBundle(
          lang,
          'translation',
          transferLocale[lang],
          true,
          true,
        );
      });
    })
    .catch((error) => {
      Sentry?.captureException(`远程接口错误: ${error}`);
    });
}
