import Track from './';

class PolicyTrack extends Track {
  /** Plocy菜单、导航栏埋点 */
  policyMenu() {
    this.simpleTrack('Page Monitor');
  }
}

export default new PolicyTrack();
