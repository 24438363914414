import request, { ResponseStructure } from '@/utils/request';
import type { AuthorityModule } from '@/pages/Authority/interface';

import { API_SMB } from '@/utils/utils';

// 配合 mock 使用
// const API_SMB = '';

/** 获取媒体全部授权信息 */
export async function getAuthList(data: AuthorityModule.Request.GetAuthList) {
  return request<ResponseStructure<AuthorityModule.Response.GetAuthList>>(
    `${API_SMB}/auth_management/get_auth_list`,
    {
      method: 'POST',
      data,
    },
  );
}

/** 获取媒体授权链接 */
export async function getAuthLink(data: AuthorityModule.Request.GetAuthLink) {
  return request<ResponseStructure<AuthorityModule.Response.GetAuthLink>>(
    `${API_SMB}/auth_management/get_auth_url`,
    {
      method: 'POST',
      data,
    },
  );
}

/** 切换当前授权 */
export async function authSwitch(data: AuthorityModule.Request.AuthSwitch) {
  return request<ResponseStructure<unknown>>( // 不需要返回类型，判断是否 success 即可
    `${API_SMB}/auth_management/auth_switch`,
    {
      method: 'POST',
      data,
    },
  );
}
