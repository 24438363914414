import classNames from 'classnames';
import { message } from 'antd';
import type { PaginationProps } from 'antd';
import { IconFont } from '@/utils/utils';
import { useTranslation } from 'react-i18next';
import { isNonEmptyString } from '@/utils/utils';
import { Step, WalktourProps } from '@/components/Tour';
import i18n from '@/i18n';
import styles from '@/components/Tour/styles.less'

const TableTotal: React.FC<{ total: number }> = ({ total }) => {
  const { t } = useTranslation();
  return (
    <span className="desc">
      {t('total')} <span className={classNames('total', 'f-w-5')}>{total}</span>{' '}
      {t('item', { count: total })}
    </span>
  );
};
export const paginationConfig: PaginationProps = {
  showTotal: (total: number) => <TableTotal total={total} />,
  showQuickJumper: true,
  showSizeChanger: true,
};

/** message.success 封装 */
export const successMessage = (content: React.ReactNode, duration = 3) => {
  if (!isNonEmptyString(content)) {
    return;
  }

  message.success({
    content,
    duration,
    className: 'custom-message success-message',
    icon: <IconFont type="icon-gou" />,
  });
};

/** message.error 封装 */
export const errorMessage = (contentVal: React.ReactNode, duration = 3) => {
  const content = isNonEmptyString(contentVal) ? contentVal : '未知错误';
  message.error({
    content,
    duration,
    className: 'custom-message error-message',
    icon: <IconFont type="icon-tanhao" />,
  });
};

/** message.warning 封装 */
export const warningMessage = (content: React.ReactNode, duration = 3) => {
  if (!isNonEmptyString(content)) {
    return;
  }

  message.warning({
    content,
    duration,
    className: 'custom-message warning-message',
    icon: <IconFont type="icon-tanhao" />,
  });
};

/** 复杂message组件，基础内容方法 */
const renderBaseComplexContent = (
  content: {
    title: string;
    description: string;
  },
  icon: React.ReactNode,
) => {
  const { title, description } = content;

  // if (!isNonEmptyString(content)) {
  //   return;
  // }

  return (
    /** 为什么直接给一个complex-container类名
     *  方便在message组件中修改样式，详细请看global.less
     */
    <div className="complex-container">
      <div className={classNames('fx-v-c', 'title')}>
        {icon}
        <span className={classNames('fz-16', 'f-w-5')}>{title}</span>
      </div>
      <span className="description">{description}</span>
    </div>
  );
};

/** 复杂的通知
 *  支持展示标题、概要
 *  基于message.error封装
 */
export const complexErrorMessage = (
  content: {
    title: string;
    description: string;
  },
  duration = 3,
) => {
  const contentVal = isNonEmptyString(content.title)
    ? content
    : { title: '未知错误', description: content.description };

  message.error({
    content: renderBaseComplexContent(
      contentVal,
      <IconFont type="icon-tanhao" />,
    ),
    duration,
    className: 'custom-complex-message complex-error-message',
    icon: null,
  });
};

/** 复杂的通知
 *  基于message.success封装
 */
export const complexSuccessMessage = (
  content: {
    title: string;
    description: string;
  },
  duration = 3,
) => {
  message.success({
    content: renderBaseComplexContent(content, <IconFont type="icon-gou" />),
    duration,
    className: 'custom-complex-message complex-success-message',
    icon: null,
  });
};

/** 复杂的通知
 *  基于message.warning封装
 */
export const complexWarningMessage = (
  content: {
    title: string;
    description: string;
  },
  duration = 3,
) => {
  message.warning({
    content: renderBaseComplexContent(content, <IconFont type="icon-tanhao" />),
    duration,
    className: 'custom-complex-message complex-warning-message',
    icon: null,
  });
};

// walkTour
/** 所有步骤 */
const getAllSteps = (): Step[] => {
  const { t } = i18n;

  return [
    // 0
    {
      selector: '.tour-intro',
      title: t('apply_for_ad_account'),
      description: t('select_the_target_media_you_want_to_open_an_account_for_click_the_account_application_button_and_fill_in_the_relevant_information_to_apply_f'),
    },
    // 1
    {
      selector: '.tour-opening-record-menu',
      title: t('view_account_opening_application_records'),
      description: t('each_submitted_account_opening_application_will_be_recorded_on_the_current_page'),
    },
    // 2
    {
      selector: '.tour-collapse-account',
      title: t('expand_account'),
      description: t('when_the_account_opening_application_is_successful_click_on__expand_account__to_view_the_specific_information_of_the_account'),
    },
    // 3
    {
      selector: '.tour-account-list-menu',
      title: t('view_specific_information_about_advertising_accounts'),
      description: t('you_can_view_all_advertising_accounts_under_the_current_valid_authorization_including_account_name_id_status_balance_etc'),
    },
    // 4
    {
      selector: '.tour-account-list',
      title: t('operation_account'),
      description: t('support_for_recharging_transferring_funds_resetting_bindingunbinding_advertising_accounts'),
    },
    // 5
    {
      selector: '.tour-recharge',
      title: t('wallet_recharge'),
      description: t('the_system_supports_recharging_the_amount_to_the_wallet_and_then_transferring_it_to_the_account_from_the_wallet'),
    },
    // 6
    {
      selector: '.tour-order-record',
      title: t('order_records'),
      description: t('can_view_records_of_wallet_recharge_account_transfer_to_wallet_and_wallet_recharge_to_account'),
    },
    // 7
    {
      selector: '.tour-order-detail',
      title: t('details_of_amount_changes'),
      description: t('record_of_wallet_income_and_expenditure'),
    },
    // 8
    {
      selector: '.tour-finance-recharge-menu',
      title: t('recharge_information'),
      description: t('can_view_the_recharge_status_of_advertising_accounts'),
    },
    // 9
    {
      selector: '.tour-finance-transfer-menu',
      title: t('transfer_information'),
      description: t('can_view_the_transfer_status_of_advertising_accounts'),
    },
    // 10
    {
      selector: '.tour-authority-menu',
      title: t('authorization_management'),
      description: t('unified_management_of_authorized_personal_accounts_including_adding_new_authorizations_and_reauthorizing_expired_authorizations'),
    },
    // 11
    {
      selector: '.tour-policy-menu',
      title: t('asset_monitor_menu'),
      description: t('monitor_whether_the_status_of_the_homepage_is_abnormal_including_indicators_such_as_newly_added_anomalies_on_the_seventh_day_and_the_number_'),
    },
  ]
}
/** common config */
export const commonConfig = (): WalktourProps => {
  const { t } = i18n;

  return {
    steps: getAllSteps(),
    disableCloseOnClick: true,
    maskPadding: 0,
    maskRadius: 12,
    nextLabel: t('next_guide'),
    prevLabel: t('prev_guide'),
    closeLabel: t('skip_guide'),
    customCloseFunc: (tourLogic) => {
      tourLogic.close();
      localStorage.setItem('skipTour', 'true');
    },
    tooltipSeparation: 36,
    customCloseClassname: styles['close-btn'],
    customNextClassname: styles['next-btn'],
    customPrevClassname: styles['prev-btn'],
  }
}
/** 跳过引导 */
export const isSkippedTour = () => localStorage.getItem('skipTour') === 'true'
