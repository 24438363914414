import {
  walletRechargeAccounts,
  getAccountCapitalInfo,
  confirmRecharge,
  getTransferAccount,
  createAccountTransferOrder,
  confirmTransferOrder,
  getMediaManageList,
  getAccountManagerList,
  submitRemoveManager,
  submitBindManager,
  getAllAccountManagerList,
  submitSnapchatAddMembers,
  submitReName,
  getTags,
  linkTag,
  unlinkTag
} from '@/services/accountList';
// @ts-ignore add TS check, handle history code
import { SagaReturnType } from 'redux-saga/effects';
import { errorMessage, successMessage } from '@/utils/config';
import { fetchErrorHandler, fetchSuccess } from '@/utils/utils';
import {
  AccountRecordType,
  CreateAccountTransferOrderType,
  TagType
} from '@/pages/AccountList/interface';
import i18n from '@/i18n';
const { t } = i18n;
enum RequestSpecialCode {
  /** 重复订单提交 */
  RepeatOrder = '400002',
}

export type AccountListState = {
  /** 批量操作 */
  batchHandle: {
    selectedRows: AccountRecordType[];
    selectedRowKeys: string[];
  };
  /** 存储清零 ｜ 转账 相关信息 */
  dealModal: {
    /** 可转账的账户列表 */
    transferAccount: {
      account_name: string;
      account_id: string;
    }[];
    /** 创建订单返回的结果 */
    backInfo: Partial<CreateAccountTransferOrderType>;
    /** 确认订单后返回的结果 */
    confirmBackInfo: { success?: boolean; failedReason?: string };
  };
  /** 自定义标签 */
  tagsList: TagType[];
};

export type AccountListModelType = {
  namespace: 'account';
  state: AccountListState;
  effects: {
    /** 获取账户授权下的商务管理信息（用户维度的信息） */
    getAccountManagerList: Effect;
    /** 获取账户授权下的商务管理信息（账户维度的信息）  */
    getAllAccountManagerList: Effect;
    /** 绑定商务管理信息 */
    submitBindManager: Effect;
    /** 解除绑定商务信息 */
    submitRemoveManager: Effect;
    /** 获取用户授权下的商务管理信息 */
    getMediaManageList: Effect;
    /** 转账确认下单 */
    confirmTransferOrder: Effect;
    /** 转账创建订单 */
    createAccountTransferOrder: Effect;
    /** 可转账的账户列表 */
    getTransferAccount: Effect;
    /** 获取账户的资金详情 */
    getAccountCapitalInfo: Effect;
    /** 钱包充值账户 创建订单 */
    walletRechargeAccounts: Effect;
    /** 钱包确认充值账户 确认订单过程 */
    confirmRecharge: Effect;
    /** Snapchat添加成员 */
    submitSnapchatAddMembers: Effect;
    /** FB、谷歌类广告账户修改名称 */
    submitReName: Effect;
    /** 获取媒体标签 */
    getTags: Effect;
    /** 关联媒体标签 */
    linkTag: Effect;
    /** 取关媒体标签 */
    unlinkTag: Effect;
  };
  reducers: {
    /** 存储清零、转账弹窗信息 */
    saveDealModal: Reducer<AccountListState>;
    /** 存储账号资金详情信息 */
    saveAccountCapitalInfo: Reducer<AccountListState>;
    /** 存储批量操作相关信息 */
    saveBatchHandle: Reducer<AccountListState>;
    /** 存储state */
    saveState: Reducer<AccountListState>;
    /** 重置state */
    resetState: Reducer<AccountListState>;
  };
};

export const defaultState: AccountListState = {
  batchHandle: {
    selectedRows: [],
    selectedRowKeys: [],
  },
  dealModal: {
    transferAccount: [],
    backInfo: {},
    confirmBackInfo: {},
  },
  tagsList: [],
};

const AccountListModel: AccountListModelType = {
  namespace: 'account',
  state: defaultState,
  effects: {
    *submitReName({ payload, onSuccess }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof submitReName> = yield call(
        submitReName,
        payload,
      );

      if (fetchSuccess(res)) {
        successMessage(t('renaming_account_name_successful'));
        return onSuccess?.(res);
      }

      fetchErrorHandler(res);
    },
    *submitSnapchatAddMembers({ payload, onSuccess }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof submitSnapchatAddMembers> = yield call(
        submitSnapchatAddMembers,
        payload,
      );

      if (fetchSuccess(res)) {
        successMessage(t('successfully_added_member'));
        return onSuccess?.(res);
      }

      fetchErrorHandler(res);
    },
    *submitBindManager({ payload, onSuccess, onFail }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof submitBindManager> = yield call(
        submitBindManager,
        payload,
      );

      if (fetchSuccess(res)) {
        return onSuccess?.(res);
      }

      return onFail?.();
    },
    *submitRemoveManager({ payload, onSuccess, onFail }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof submitRemoveManager> = yield call(
        submitRemoveManager,
        payload,
      );

      if (fetchSuccess(res)) {
        return onSuccess?.(res);
      }

      return onFail?.();
    },
    *getAccountManagerList({ payload, onSuccess }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof getAccountManagerList> = yield call(
        getAccountManagerList,
        payload,
      );
      if (fetchSuccess(res)) {
        return onSuccess?.(res);
      }
      fetchErrorHandler(res);
    },
    *getAllAccountManagerList({ payload, onSuccess }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof getAllAccountManagerList> = yield call(
        getAllAccountManagerList,
        payload,
      );
      if (fetchSuccess(res)) {
        return onSuccess?.(res);
      }
      fetchErrorHandler(res);
    },
    *getMediaManageList({ payload, requestSuccess, requestFail }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof getMediaManageList> = yield call(
        getMediaManageList,
        payload,
      );

      if (fetchSuccess(res)) {
        return requestSuccess(res);
      }

      return requestFail ? requestFail(res) : fetchErrorHandler(res);
    },
    *confirmTransferOrder({ payload, requestSuccess }, { call, put }) {
      // @ts-ignore add TS check, handle history code
      const res = yield call(confirmTransferOrder, payload);
      /** 成功 */
      if (fetchSuccess(res)) {
        yield put({
          type: 'saveDealModal',
          payload: {
            confirmBackInfo: {
              success: true,
            },
          },
        });
        requestSuccess();
      }
      /** 失败 */
      if (!fetchSuccess(res)) {
        yield put({
          type: 'saveDealModal',
          payload: {
            confirmBackInfo: {
              success: false,
              failedReason: res.info,
            },
          },
        });
        requestSuccess();
      }
    },
    *createAccountTransferOrder({ payload, requestSuccess }, { call, put }) {
      // @ts-ignore add TS check, handle history code
      const res = yield call(createAccountTransferOrder, payload);
      if (fetchSuccess(res)) {
        yield put({
          type: 'saveDealModal',
          payload: {
            backInfo: res.data,
          },
        });
        requestSuccess();
      }
      if (!fetchSuccess(res)) {
        errorMessage(res.info);
      }
    },
    *getTransferAccount({ payload, onSuccess, onFail }, { call, put }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof getTransferAccount> = yield call(
        getTransferAccount,
        payload,
      );
      if (fetchSuccess(res)) {
        onSuccess?.(res.data);

        yield put({
          type: 'saveDealModal',
          payload: {
            transferAccount: res.data,
          },
        });
      }
      if (!fetchSuccess(res)) {
        errorMessage(res.info);
        onFail?.();
      }
    },
    *getAccountCapitalInfo({ payload, onSuccess, onFail }, { call, put }) {
      // @ts-ignore add TS check, handle history code
      const res = yield call(getAccountCapitalInfo, payload);
      if (fetchSuccess(res)) {
        if (onSuccess) {
          onSuccess(res.data);

          return;
        }

        yield put({
          type: 'saveAccountCapitalInfo',
          payload: res.data,
        });
      }
      if (!fetchSuccess(res)) {
        errorMessage(res.info);
        onFail?.();
      }
    },
    *walletRechargeAccounts({ payload, requestSuccess }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res = yield call(walletRechargeAccounts, payload);
      if (fetchSuccess(res)) {
        requestSuccess(res.data);
      }
      if (!fetchSuccess(res)) {
        errorMessage(res.info);
      }
    },
    *confirmRecharge({ payload, requestSuccess, requestRepeat }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res = yield call(confirmRecharge, payload);
      if (fetchSuccess(res)) {
        requestSuccess(res.data);
      }
      if (!fetchSuccess(res)) {
        if (res.code === RequestSpecialCode.RepeatOrder) {
          successMessage(t('the_application_has_been_successfully_submitted_please_go_to_financial_management_to_check_the_status_of_the_account_received'));
          requestRepeat();
        }
      }
    },
    *getTags({ payload }, { call, put }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof getTags> = yield call(
        getTags,
        payload,
      );

      if (fetchSuccess(res)) {
        yield put({
          type: 'saveState',
          payload: {
            tagsList: res?.data?.tags,
          },
        });
        return;
      }
    },
    *unlinkTag({ payload, onSuccess }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof unlinkTag> = yield call(
        unlinkTag,
        payload,
      );

      if (fetchSuccess(res)) {
        successMessage(t('successfully_deleted_label'));
        return onSuccess?.(res);
      }

      fetchErrorHandler(res);
    },
    *linkTag({ payload, onSuccess }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof linkTag> = yield call(
        linkTag,
        payload,
      );

      if (fetchSuccess(res)) {
        successMessage(t('label_added_successfully'));
        return onSuccess?.(res);
      }

      fetchErrorHandler(res);
    },
  },
  reducers: {
    saveDealModal(state, { payload }) {
      return {
        ...state,
        dealModal: {
          ...state.dealModal,
          ...payload,
        },
      };
    },
    saveAccountCapitalInfo(state, { payload }) {
      return {
        ...state,
        capitalInfo: payload,
      };
    },
    saveBatchHandle(state, { payload }) {
      return {
        ...state,
        batchHandle: {
          ...state.batchHandle,
          ...payload,
        },
      };
    },
    saveState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    resetState() {
      return {
        ...defaultState,
      };
    },
  },
};

export default AccountListModel;
