import { Tooltip } from 'antd';
import classNames from 'classnames';
import { IconFont } from '@/utils/utils';
import AvatarDropdown from './AvatarDropdown';
import AssetMonitor from './AssetMonitor';
import styles from './index.less';
import { useTranslation } from 'react-i18next';

import header_wechat from '@/assets/images/header_wechat.svg';
import online from '@/assets/images/header_online.svg';
import header_phone from '@/assets/images/header_phone.svg';
import { Box } from './Box';
import wechat from '@/assets/images/wechat.svg';
import phone from '@/assets/images/phone.svg';
import { useEffect } from 'react';
import Track from '@/tracks';

const GlobalHeaderRight: React.FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    zc && zc('dynamic_ready');
  }, []);
  return (
    <div className={classNames('fx-v-c', styles.right)}>
      <div className={classNames('fx-v-c', styles.operate)}>
        <AssetMonitor />
        <a
          href="/partner"
          target="_blank"
          rel="noreferrer"
          className={classNames(
            'f-w-5',
            styles.seaSchool,
            'fx-v-c',
            styles.partner,
          )}
        >
          {t('luban_partner')}
        </a>
        <a
          href="https://luban-academy.bluemediagroup.cn/"
          target="_blank"
          rel="noreferrer"
          className={classNames('f-w-5', styles.seaSchool, 'fx-v-c')}
        >
          {t('overseas_college')}
        </a>
        <Tooltip title={t('help_center')}>
          <div className={classNames('fx-v-c', styles.helpCenter)}>
            <IconFont
              className="fz-20 c-p"
              type="icon-bangzhu"
              title={t('help_center')}
              onClick={() =>
                window.open(
                  '/guide',
                  '_blank',
                )
              }
            />
          </div>
        </Tooltip>
        <Tooltip title={t('online_consultant')}>
          <div
            className={classNames(
              'zhiCustomBtn',
              'fx-v-c',
              styles.online,
            )}
            onClick={ () => Track.track('在线客服咨询') }
          >
            <img src={online} alt="" className='c-p'/>
          </div>
        </Tooltip>
        <Box
          imgSrc={header_wechat}
          title={t('follow_the_wechat_official_account')}
          desc={t('first_hand_policy_updates_in_real_time')}
          contentImg={wechat}
          onMouseOver={ () => Track.track('微信咨询') }
        />
        <Box
          imgSrc={header_phone}
          title={t('helpline')}
          desc={t('communicate_with_professionals_about_advertising_business')}
          contentImg={phone}
          content="010-53392331"
        />
      </div>
      <AvatarDropdown />
    </div>
  );
};
export default GlobalHeaderRight;
