import { memo } from 'react';
import { useModel } from '@umijs/max';
import XModal from '@/components/XModal';
import type { XDescriptionConfigType } from '@/components/XDescription';
import XDescription from '@/components/XDescription';
import { SettlementType } from '@/utils/enum';
// @ts-ignore add TS check, handle history code
import { EmptyText, trimSpace } from '@smb_mono/utils';
import { useTranslation } from 'react-i18next';
import { fetchSuccess, useI18nTransform } from '@/utils/utils';
import { Form, Input } from 'antd';
import classNames from 'classnames';
import { updateBusinessInfo } from '@/services/user';
import { errorMessage, successMessage } from '@/utils/config';
import styles from './index.less';

type BusinessInfoProps = {
  visible: boolean;
  onCancel: () => void;
};

const settlementTypeMap = (type?: SettlementType) => {
  if (!type) return EmptyText;
  return {
    // NOTE:  为国际化 textId
    [SettlementType.Prepaid]: 'advance',
    [SettlementType.QuotaPrepaid]: 'prepaid_credit_limit',
  }[type];
};

// NOTE: label 为国际化 textId
const businessInfoConfig: XDescriptionConfigType[] = [
  {
    key: 'business_name',
    label: 'payment_company_name',
  },
  {
    key: 'settlement_type',
    label: 'settlement_method',
  },
];

/** 企业信息 */
const BusinessInfo: React.FC<BusinessInfoProps> = ({ visible, onCancel }) => {
  const { t } = useTranslation();
  const { initialState: { businessInfo = {} } = {}, setInitialState } =
    useModel('@@initialState');

  const [form] = Form.useForm<Partial<API.BusinessInfo<SettlementType>>>();
  const isAgency = !!businessInfo?.is_seb;
  const handleSubmit = async () => {
    try {
      const value = await form.validateFields();
      const res = await updateBusinessInfo({
        payment_name: value.payment_name,
      });
      const business_name = value.payment_name;
      if (fetchSuccess(res)) {
        successMessage(t('modified_successfully'));
        onCancel();
        setInitialState((state) => ({
          ...state,
          businessInfo: { ...state?.businessInfo, business_name },
        }));
      }
      if (!fetchSuccess(res)) {
        errorMessage(t('modification_failed_please_try_again'));
      }
    } catch (e) {}
  };
  return (
    <XModal
      open={visible}
      onCancel={onCancel}
      title={t('enterprise_information')}
      centered
      cancelText={t('return')}
      okText={isAgency ? t('save_and_modify') : null}
      okButtonProps={{
        className: classNames({
          [styles.saveBtnNo]: !isAgency,
        }),
      }}
      onOk={handleSubmit}
      maskClosable={false}
    >
      {isAgency ? (
        <Form layout="vertical" className={classNames('userForm')} form={form}>
          <Form.Item
            initialValue={businessInfo?.business_name}
            label={t('payment_company_name')}
            name="payment_name"
            getValueFromEvent={trimSpace}
            rules={[
              {
                required: true,
                message: t('please_enter_the_name_of_the_payment_company'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            initialValue={t(settlementTypeMap(businessInfo?.settlement_type))}
            label={t('settlement_method')}
            name="settlement_type"
          >
            <Input disabled />
          </Form.Item>
        </Form>
      ) : (
        <XDescription
          config={useI18nTransform(businessInfoConfig, 'label').map((item) => ({
            ...item,
            value:
              item.key === 'settlement_type'
                ? t(settlementTypeMap(businessInfo[item.key]))
                : businessInfo[item.key],
          }))}
        />
      )}
    </XModal>
  );
};

export default memo(BusinessInfo);
