import { useState } from 'react';
import { useRequest } from 'ahooks';
import {
  getAssetMonitorInfo,
  setAssetMonitor as fetchSetAssetMonitor,
} from '@/services/user';
import { ResponseStructure } from '@/utils/request';
import { fetchSuccess } from '@/utils/utils';

enum UserNotify {
  /** 不提醒 */
  UnRemind,
  /** 提醒 */
  Remind,
}
/** 获取当前用户下资产监控的通知数量 */
type GetAssetMonitorInfo = {
  /** 近七日新增异常主页数量 */
  last7d_new_disapproved_page_number?: number;
  /** 当前是否已读 */
  notice_status?: 'READ' | 'UNREAD';
};

/** 点击资产监控后，通知服务端已经点击，接口返回的参数 */
type SetAssetMonitorInfo = {
  /** 近七日新增异常主页数量 */
  last7d_new_disapproved_page_number?: number;
  /** 是否用户提醒
   *  0 不提醒
   *  1 提醒
   */
  user_notify?: UserNotify;
};

type Info = {
  /** 显示数量 */
  total?: number;
  /** 是否显示用户提示框 */
  showTooltip?: boolean;
};

const defaultInfo: Info = {
  total: 0,
  showTooltip: false,
};

export default () => {
  const [info, setInfo] = useState<Info>(defaultInfo);

  /** 获取是否资产监控提醒 */
  const { run } = useRequest(getAssetMonitorInfo, {
    manual: true,
    onSuccess(res: ResponseStructure<GetAssetMonitorInfo>) {
      if (fetchSuccess(res)) {
        setInfo({
          total: res.data.last7d_new_disapproved_page_number,
          /** 用户未读状态显示提示框 */
          showTooltip: res.data.notice_status === 'UNREAD',
        });
      }
    },
    onError() {
      setInfo(defaultInfo);
    },
  });

  /** 点击资产监控后通知服务端，代表用户已经读取 */
  const { run: setAssetMonitor } = useRequest(fetchSetAssetMonitor, {
    manual: true,
    onSuccess(res: ResponseStructure<SetAssetMonitorInfo>) {
      if (fetchSuccess(res)) {
        setInfo({
          total: res.data.last7d_new_disapproved_page_number,
          showTooltip: res.data.user_notify === UserNotify.Remind,
        });
      }
    },
    onError() {
      setInfo(defaultInfo);
    },
  });

  return {
    info,
    getAssetMonitorInfo: run,
    setAssetMonitor,
  };
};
