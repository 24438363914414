// @ts-ignore add TS check, handle history code
import { loadScript } from '@bluevision/tools';
// @ts-ignore add TS check, handle history code
import * as Sentry from '@sentry/browser';

const VERSION_FILENAME = 'app_version.js';
const VERSION_STORAGE_KEY = 'CURRENT_APP_VERSION';

interface CheckReloadAppConfig {
  onCheckPass?: () => void;
}

/** 检查 APP 最新版本号，如果和当前版本不一致，则更新版本号且 reload APP，防止老版本文件访问不到，用户无法使用 */
const checkReloadApp = (config?: CheckReloadAppConfig) => {
  const { onCheckPass } = config || {}; // ！只要不是版本号不一致的情况，都算作检测通过

  loadScript({
    assetBaseURL: window.location.origin,
    filename: VERSION_FILENAME // 此名称取决于 @bluevision/fe-plugins 中的 VersionPlugin 插件，未扩展名称传入
  }).then(() => {
    // 1. 读取 window.__app_version__，这个是最新版的打包时间戳，判断是否等于 localStorage 中的时间戳
    const currentVersion = localStorage.getItem(VERSION_STORAGE_KEY);
    // @ts-ignore window 类型不知道，标记为 unknown
    const newestVersion = ((window as unknown)?.__app_version__ as string) + ''; // 转换为 string 作比较，因为 localStorage 中存的是字符串
    if (currentVersion && newestVersion && currentVersion === newestVersion) { // 1.1 版本相同，无逻辑
      onCheckPass?.();
    } else { // 1.2 版本不同，应该更新为新版本，且更新 localStorage 中的版本号为 newestVersion
      localStorage.setItem(VERSION_STORAGE_KEY, newestVersion);
      /**
       * 先更新 url，此行原因不明，参考传媒数据组的代码：
       * https://git.domob-inc.cn/bluefocusai/mindbooster_web/-/blob/feature/designer2/src/hooks/useVersionCheck.ts，
       * 可能是为了安全或者兼容一些情况
       */
      window.location.href = location.href || window.location.href;
      window.location.reload(); //再 reload 加载新数据
    }
    // @ts-ignore add TS check, handle history code
  }).catch((err) => {
    onCheckPass?.();
    Sentry?.captureException(err);
  })
}

export {
  checkReloadApp
}
