import {
  getPayoneerUserInfo,
  getPayoneerOauthUrl,
  payoneerDebit,
  pingPongDebit,
  rechargeWallet,
  payoneerConfirm,
} from '@/services/wallet';
import type { WalletOrderMsg } from '@/pages/Wallet/data.d';
import { complexErrorMessage } from '@/utils/config';
import { fetchSuccess } from '@/utils/utils';
import i18n from '@/i18n';

const { t } = i18n;

type PayoneerUserInfo = {
  account_id: string;
  /** payoneer 状态 0：授权失效，1：已授权 */
  status: 0 | 1;
};

type PayoneerSubmitError = {
  /** 授权是否过期 */
  outDate: boolean;
  /** 错误信息 */
  info: string;
};

export type WalletModelState = {
  payoneerUserInfo?: PayoneerUserInfo;
  payoneerAuthUrl: string;
  payoneerSubmitError?: PayoneerSubmitError;
  payoneerResultShow: boolean;
  payoneerResultInfo?: string;
  payoneerResultData?: { order_status?: number };
  payoneerTips: boolean;
  mfaStatus: number;
  showOrder: boolean;
  orderMsg: WalletOrderMsg;
};

export type WalletModelType = {
  namespace: 'wallet';
  state: WalletModelState;
  effects: {
    getPayoneerUserInfo: Effect;
    getPayoneerOauthUrl: Effect;
    submit: Effect;
    submitPayoneer: Effect;
    submitPingpong: Effect;
    confirmPayoneerInfo: Effect;
  };
  reducers: {
    saveState: Reducer<WalletModelState>;
    changePayoneerTips: Reducer<WalletModelState>;
  };
};

const WalletModel: WalletModelType = {
  namespace: 'wallet',
  state: {
    /** payoneer 账户信息
     *
     * payoneerUserInfo 为 undefined 表示未授权
     * payoneerUserInfo.status 0 表示授权失效
     * payoneerUserInfo.status 1 表示已授权
     */
    payoneerUserInfo: undefined,
    /** payoneer 授权 url */
    payoneerAuthUrl: '',
    /** 皮卡下单错误信息 */
    payoneerSubmitError: undefined,
    /** 皮卡确认订单后的弹窗 */
    payoneerResultShow: false,
    /** 皮卡确认订单后返回的提示信息 */
    payoneerResultInfo: '',
    /** 皮卡确认订单后返回的数据 */
    payoneerResultData: {},
    /** 皮卡账户信息弹窗 */
    payoneerTips: false,
    /** payoneer MFA 成功后的默认状态 */
    mfaStatus: 100,
    /** 下单弹窗 */
    showOrder: false,
    /** 下单-订单详情 */
    orderMsg: {},
  },
  effects: {
    *getPayoneerUserInfo(_, { call, put }) {
      // @ts-ignore add TS check, handle history code
      const res = yield call(getPayoneerUserInfo);

      if (fetchSuccess(res)) {
        yield put({
          type: 'saveState',
          payload: { payoneerUserInfo: res.data },
        });
      }
    },
    *getPayoneerOauthUrl(_, { call, put }) {
      // @ts-ignore add TS check, handle history code
      const res = yield call(getPayoneerOauthUrl);

      if (fetchSuccess(res)) {
        yield put({
          type: 'saveState',
          payload: { payoneerAuthUrl: res.data?.oauth_url ?? '' },
        });
      }
    },
    *submit({ payload }, { call, put }) {
      yield put({ type: 'saveState', payload: { orderMsg: {} } });
      // @ts-ignore add TS check, handle history code
      const res = yield call(rechargeWallet, payload);

      if (fetchSuccess(res)) {
        yield put({
          type: 'saveState',
          payload: {
            showOrder: true,
            orderMsg: res.data,
          },
        });
        return;
      }

      if (!fetchSuccess(res)) {
        complexErrorMessage({
          title: t('submission_failed_please_try_again'),
          description: res.info,
        });
      }
    },
    *submitPayoneer({ payload }, { call, put }) {
      yield put({
        type: 'saveState',
        payload: { payoneerSubmitError: undefined, orderMsg: {} },
      });

      // @ts-ignore add TS check, handle history code
      const res = yield call(payoneerDebit, payload);

      if (fetchSuccess(res)) {
        yield put({
          type: 'saveState',
          payload: {
            showOrder: true,
            orderMsg: res.data,
          },
        });
      }

      if (!fetchSuccess(res)) {
        yield put({
          type: 'saveState',
          payload: {
            payoneerSubmitError: {
              outDate: res.data?.out_date,
              info: res.info,
            },
            payoneerTips: true,
          },
        });
      }
    },
    *submitPingpong({ payload }, { call, put }) {
      yield put({ type: 'saveState', payload: { orderMsg: {} } });
      // @ts-ignore add TS check, handle history code
      const res = yield call(pingPongDebit, payload);

      if (fetchSuccess(res)) {
        yield put({
          type: 'saveState',
          payload: {
            showOrder: true,
            orderMsg: res.data,
          },
        });
        return;
      }

      if (!fetchSuccess(res)) {
        complexErrorMessage({
          title: t('submission_failed_please_try_again'),
          description: res.info,
        });
      }
    },
    *confirmPayoneerInfo({ payload }, { call, put }) {
      // @ts-ignore add TS check, handle history code
      const res = yield call(payoneerConfirm, payload);

      const { data } = res || {};

      // 订单确认失败
      if (!fetchSuccess(res)) {
        complexErrorMessage({
          title: t('submission_failed'),
          description: res.info,
        });
      }

      // 订单成功
      if (fetchSuccess(res)) {
        yield put({
          type: 'saveState',
          payload: {
            payoneerResultInfo: res.info,
            payoneerResultData: res.data,
            showOrder: false,
            payoneerResultShow: true,
          },
        });
      }

      // MFA 验证
      if (fetchSuccess(res) && data.challenge_url) {
        window.location.href = data.challenge_url;
      }
    },
  },
  reducers: {
    saveState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    changePayoneerTips(state) {
      const { payoneerUserInfo, payoneerSubmitError } = state;

      if (!payoneerUserInfo?.status || payoneerSubmitError) {
        return {
          ...state,
          payoneerTips: true,
        };
      }

      return state;
    },
  },
};

export default WalletModel;
