import {
  getIndustry,
  getAllTimeZone,
  getAllRegisteredArea,
  getImageVerify,
  submitTiktokLicenseUpload,
  submitAccountApply,
  submitSmallAccountApply,
} from '@/services/openingOutline';
import { IMediaApply } from '@/pages/MediaApplyForm/type';
import { fetchSuccess, fetchErrorHandler } from '@/utils/utils';
// @ts-ignore add TS check, handle history code
import { SagaReturnType } from 'redux-saga/effects';
import { errorMessage } from '@/utils/config';

export type OpeningOutlineState = {
  /** 产品行业信息 */
  industryList:
    | IMediaApply.Response.IndustrySelectList
    | IMediaApply.Response.CommonSelectList;
  /** 开户时区 */
  timeZoneList: IMediaApply.Response.CommonSelectList;
  /** 投放国家注册地 */
  registeredAreaList: IMediaApply.Response.CommonSelectList;
};

export type OpeningOutlineModelType = {
  namespace: 'openingOutline';
  state: OpeningOutlineState;
  effects: {
    /** 获取产品行业 */
    getIndustry: Effect;
    /** 获取开户时区 */
    getAllTimeZone: Effect;
    /** 获取投放国家 */
    getAllRegisteredArea: Effect;
    /** 营业执照识别 */
    getImageVerify: Effect;
    /** 营业执照上传Tiktok后台 */
    submitTiktokLicenseUpload: Effect;
    /** 提交开户申请 */
    submitAccountApply: Effect;
    /** 小媒体提交开户申请 */
    submitSmallAccountApply: Effect;
  };
  reducers: {
    saveState: Reducer<OpeningOutlineState>;
    resetState: Reducer<OpeningOutlineState>;
  };
};

export const defaultState: OpeningOutlineState = {
  industryList: [],
  timeZoneList: [],
  registeredAreaList: [],
};

const OpeningOutlineModel: OpeningOutlineModelType = {
  namespace: 'openingOutline',
  state: defaultState,
  effects: {
    *submitAccountApply({ payload, requestSuccess, requestFail }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof submitAccountApply> = yield call(
        submitAccountApply,
        payload,
      );

      if (fetchSuccess(res)) {
        requestSuccess?.();
        return;
      }

      return requestFail ? requestFail(res) : fetchErrorHandler(res);
    },
    *submitSmallAccountApply(
      { payload, requestSuccess, requestFail },
      { call },
    ) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof submitSmallAccountApply> = yield call(
        submitSmallAccountApply,
        payload,
      );

      if (fetchSuccess(res)) {
        requestSuccess?.();
        return;
      }

      return requestFail ? requestFail(res) : fetchErrorHandler(res);
    },
    *getIndustry({ payload }, { call, put }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof getIndustry> = yield call(
        getIndustry,
        payload,
      );

      if (fetchSuccess(res)) {
        yield put({
          type: 'saveState',
          payload: {
            industryList: res.data,
          },
        });
        return;
      }
    },
    *getAllTimeZone({ payload }, { call, put }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof getAllTimeZone> = yield call(
        getAllTimeZone,
        payload,
      );

      if (fetchSuccess(res)) {
        yield put({
          type: 'saveState',
          payload: {
            timeZoneList: res.data,
          },
        });
        return;
      }
    },
    *getAllRegisteredArea({ payload }, { call, put }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof getAllRegisteredArea> = yield call(
        getAllRegisteredArea,
        payload,
      );

      if (fetchSuccess(res)) {
        yield put({
          type: 'saveState',
          payload: {
            registeredAreaList: res.data,
          },
        });
        return;
      }
    },
    *getImageVerify({ payload, requestSuccess }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof getImageVerify> = yield call(
        getImageVerify,
        payload,
      );

      if (fetchSuccess(res)) {
        requestSuccess?.(res.data);
        return;
      }

      errorMessage(res.info);
    },
    *submitTiktokLicenseUpload({ payload, onSuccess }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof submitTiktokLicenseUpload> = yield call(
        submitTiktokLicenseUpload,
        payload,
      );

      if (fetchSuccess(res)) {
        onSuccess?.(res);
        return;
      }

      errorMessage(res.info);
    },
  },
  reducers: {
    saveState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    resetState() {
      return {
        ...defaultState,
      };
    },
  },
};

export default OpeningOutlineModel;
