import { useModel } from "@umijs/max";
import { logo, logoEn } from '@/utils/constant';
import { useTranslation } from "react-i18next";
import { Locales } from "@/i18n";
import { useMemo } from "react";
import { BoolEnum } from "@/utils/enum";
import defaultSettings from "./../../../config/defaultSettings";

const Logo: React.FC<{
  className?: string;
}> = ({className}) => {
  const { initialState } = useModel('@@initialState');
  const { i18n } = useTranslation();

  const currentLogo = useMemo(() => {
    const role = initialState?.currentUser?.is_business_member;

    // PLUS 用户 ==> PLUS logo
    if (role === BoolEnum.YES) {
      return defaultSettings.logo;
    }

    // 鲁班 用户 ==> 中英文切换 logo
    if (i18n.language === Locales.enUS){
      return logoEn;
    }

    return logo;
  }, [i18n.language, initialState?.currentUser?.is_business_member])
  
  return <img src={ currentLogo } alt="Logo" className={className} />;
};

export default Logo;
