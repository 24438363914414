import { useEffect } from 'react';
import { Tooltip } from 'antd';
import { Link, useModel } from '@umijs/max';
import classNames from 'classnames';
import AssetTag from './AssetTag';
import { IconFont } from '@/utils/utils';
import policyTrack from '@/tracks/policy';
import styles from './index.less';
import { useTranslation } from 'react-i18next';

const AssetMonitor = () => {
  const { getAssetMonitorInfo, info, setAssetMonitor } =
    // @ts-ignore add TS check, handle history code
    useModel('useAssetMonitor');

  const { t } = useTranslation();

  const handleLinkPolicy = () => {
    /** 资产监控埋点 */
    policyTrack.policyMenu();
    setAssetMonitor();
  };

  const render = () => (
    <Link
      to={{ pathname: '/policy' }}
      className="c-w"
      onClick={handleLinkPolicy}
    >
      <div className={classNames('c-p', 'fx-v-c', 'f-w-5')}>{t('asset_monitor')}</div>
    </Link>
  );

  useEffect(() => {
    getAssetMonitorInfo();
  }, [getAssetMonitorInfo]);

  return (
    <section className={classNames(styles.assetMonitor, 'fx-v-c')}>
      {info.showTooltip ? (
        <Tooltip
          title={
            <div className={classNames(styles.assetTooltip, 'fx-v-c')}>
              <div className={classNames(styles.mask, 'fx-h-v-c')}>
                <IconFont
                  type="icon-a-7rixinzengyichang"
                  className={classNames('fz-20', styles.icon)}
                />
              </div>
              <span className={classNames(styles.desc, 'f-w-5')}>
                {t('seven_day_new_outlier')}
              </span>
              <span className={classNames(styles.total, 'fz-20')}>
                +{info?.total}
              </span>
            </div>
          }
          placement="bottom"
          color="#fff"
        >
          {render()}
        </Tooltip>
      ) : (
        render()
      )}

      {info.showTooltip && <AssetTag total={info?.total || 0} />}
    </section>
  );
};

export default AssetMonitor;
