import { Result, Button } from 'antd';
// @ts-ignore add TS check, handle history code
import * as Sentry from '@sentry/browser';
import React from 'react';
// @ts-ignore add TS check, handle history code
import { session } from '@smb_mono/utils';
import { checkReloadApp } from '@/utils/versionCheck';
import PageLoading from '@/loading';
import { withTranslation } from 'react-i18next';

const chunkLoadErrorReg = /Loading chunk (\d)+ failed/g;
// chunk 错误加载重试次数
const chunkErrorRetryCount = 'chunk_error_retry_count';

type ErrorBoundaryState = {
  /** 是否存在错误 */
  hasError: boolean;
  /** 存在错误时的错误信息 */
  errorInfo: string;
  /** 当 chunk load error 时，是否正在刷新页面 */
  chunkErrorLoading: boolean;
};

// eslint-disable-next-line @typescript-eslint/ban-types
class ErrorBoundary extends React.Component<
  { children?: React.ReactNode; t: (key: string) => string },
  ErrorBoundaryState
> {
  state = { hasError: false, errorInfo: '', chunkErrorLoading: false };

  static getDerivedStateFromError(error: Error) {
    // 处理 chunk load error
    if (error?.message?.match(chunkLoadErrorReg)) {
      checkReloadApp({
        onCheckPass: () => {
          // 如果没有新版本，那么是其他原因导致，上报错误
          Sentry?.captureException(error);
        },
      }); // 检测 APP 是否有新版本，有的话主动刷新

      // 重试次数
      const chunkErrorCount = session.get(chunkErrorRetryCount) || 0;

      // 重试次数大于等于 2 次，让用户手动刷新
      if (Number(chunkErrorCount) >= 2) {
        return {
          hasError: true,
          errorInfo: error.message,
          chunkErrorLoading: false,
        };
      } else {
        // 自动刷新
        session.set(chunkErrorRetryCount, Number(chunkErrorCount) + 1);

        window.location.reload();
        return { chunkErrorLoading: true };
      }
    } else {
      // 上报全局错误
      Sentry?.captureException(error);

      // 处理其他错误
      return {
        hasError: true,
        errorInfo: error.message,
        chunkErrorLoading: false,
      };
    }
  }

  handleClick = () => {
    session.remove(chunkErrorRetryCount);
    window.location.reload();
  };

  render() {
    const { t } = this.props;

    if (this.state.chunkErrorLoading) {
      return <PageLoading />;
    }

    if (this.state.hasError) {
      return (
        <Result
          status="error"
          title="Something went wrong."
          extra={
            <div className="fx-dir fx-v-c">
              <span>{this.state.errorInfo}</span>
              <Button
                type="primary"
                style={{ marginTop: 20 }}
                onClick={this.handleClick}
              >
                {t('refresh_page')}
              </Button>
            </div>
          }
        />
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
