import { useState } from 'react';
import { useRequest } from 'ahooks';
import { getOEOpenUrl } from '@/services/accountList';

export default () => {
  const [info, setInfo] = useState<{ oe_url?: string; oeitb_url?: string }>({});

  const {
    loading,
    run: getOEInfo,
    /** 异步调用 返回一个promise对象 */
    runAsync: getOEInfoAsync,
  } = useRequest(getOEOpenUrl, {
    manual: true,
    onSuccess(res) {
      setInfo(res.data || {});
    },
    onError() {
      setInfo({});
    },
  });

  return {
    loading,
    info,
    getOEInfo,
    getOEInfoAsync,
  };
};
