import * as Sentry from '@sentry/browser';
import { history, RunTimeLayoutConfig } from '@umijs/max';

import RightContent from '@/components/RightContent';
import MenuRender from '@/components/SiderMenu/MenuRender';
import ErrorBoundary from '@/components/ErrorBoundary';
import { getUserInfo, getBusinessInfo } from '@/services/user';
import { getCGrayInfo, getGrayInfo } from '@/services/grayInfo';
import { requestConfig } from '@/utils/request';
import { fetchSuccess } from '@/utils/utils';
import { setUsername, setuserRole } from '@/tracks';
import { CGrayInfo, GrayInfo } from "@/interface/gray";
import { transformPageTitle } from '@/utils/i18n';

import i18n from './i18n';
import defaultSettings from '../config/defaultSettings';
import { type SettlementType } from './utils/enum';
import { version } from '../package.json';
import { checkReloadApp } from './utils/versionCheck';
import { opsigenesInit } from '@/opsigenesSDK';
import { parse } from 'qs';
import { isSkippedTour } from './utils/config';
import { homePages } from './utils/constant';
import Logo from './components/SiderMenu/Logo';
import styles from './app.less';

import '@bluevision/components/index.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__APP_VERSION = version;

const ignoreLogin = ['/user', '/protocol', '/partner', '/guide'];

const fetchUserInfo = async () => {
  try {
    const res = await getUserInfo();

    if (fetchSuccess(res)) {
      // 设置 user 到 sentry 上，跟随每一次错误上报
      Sentry.setUser(res.data);

      return res.data;
    }
  } catch (error) {}

  return undefined;
};

const fetchBusinessInfo = async () => {
  try {
    const res = await getBusinessInfo();

    if (fetchSuccess(res)) {
      return res.data;
    }
  } catch (e) {}

  return undefined;
};

const fetchGrayInfo = async () => {
  try {
    const res = await getGrayInfo();

    if (fetchSuccess(res)) {
      return res.data;
    }
  } catch (e) {}

  return undefined;
};

const fetchCGrayInfo = async () => {
  try {
    const res = await getCGrayInfo();

    if (fetchSuccess(res)) {
      return res.data;
    }
  } catch (e) {}

  return undefined;
};

export async function getInitialState(): Promise<{
  currentUser?: API.UserInfo;
  businessInfo?: Partial<API.BusinessInfo<SettlementType>>;
  grayInfo?: GrayInfo;
  loading?: boolean;
  fetchUserInfo?: () => Promise<API.UserInfo | undefined>;
  fetchBusinessInfo?: () => Promise<
    API.BusinessInfo<SettlementType> | undefined
  >;
  fetchGrayInfo?: () => Promise<GrayInfo | undefined>;
  fetchCGrayInfo?: () => Promise<CGrayInfo | undefined>;
  cGrayInfo?: CGrayInfo
}> {
  // 代理商 code
  const query = parse(history.location.search, {
    ignoreQueryPrefix: true,
    comma: true,
  })
  const code = query?.code || '';
  if (code) {
    localStorage.setItem('agent_code', code as string);
  }

  // 如果不是登录页面，执行
  if (!ignoreLogin.some((item) => location.pathname.startsWith(item))) {
    const currentUser = await fetchUserInfo();
    setUsername(currentUser?.username);
    setuserRole(currentUser?.is_business_member);
    let businessInfo: Partial<API.BusinessInfo<SettlementType>> | undefined =
      undefined;

    if (currentUser) {
      businessInfo = await fetchBusinessInfo();
    }

    const grayInfo = await fetchGrayInfo();
    const cGrayInfo = await fetchCGrayInfo();

    if (!isSkippedTour() && !homePages.includes(location.pathname)) {
      history.push('/opening-account/outline');
    }

    return {
      currentUser,
      fetchUserInfo,
      businessInfo,
      fetchBusinessInfo,
      grayInfo,
      fetchGrayInfo,
      fetchCGrayInfo,
      cGrayInfo,
    };
  }

  return {
    fetchUserInfo,
    fetchBusinessInfo,
  };
}

export const layout: RunTimeLayoutConfig = () => {
  return {
    siderWidth: 240,
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    // 路由切换时触发
    onPageChange: () => {
      checkReloadApp();
    },
    menuRender: (props, dom) => (
      <MenuRender
        config={props}
        dom={dom as React.ReactElement}
        collapsedWidth={72}
      />
    ),
    pageTitleRender: (_, __, info) => {
      return transformPageTitle(info?.title ?? i18n.t('luban_plus'));
    },
    ErrorBoundary,
    ...defaultSettings,
    // 根据用户角色判断显示不同的 logo
    logo: <Logo className={styles.logo} />,
    };
};

/** 全局非 React 错误捕获 */
window.addEventListener('error', (event: unknown) => {
  Sentry?.captureException(event, {
    extra: { type: 'global error catch' },
  });
});
// 初始化opsigenes
opsigenesInit();
// @ts-ignore
zc('config', {
  custom: true, //设置自定义生效
});

export const request = requestConfig;
