import { IDashboard } from '@/pages/Dashboard/interface';
import request from '@/utils/request';
import { API_SMB } from '@/utils/utils';

// const API_SMB = '';

const apiWorkbench = `${API_SMB}/workbench`;

/**
 * 获取用户总体数据
 */
export async function getWorkbenchCustomerInfo() {
  return request<IDashboard.Response.CustomerInfo>(
    `${apiWorkbench}/customer/info`,
  );
}

/**
 * 获取用户账号管理媒体数据
 */
export async function getWorkbenchAccountMediaInfo() {
  return request<IDashboard.Response.AccountManagement>(
    `${apiWorkbench}/accounts/media_info`,
  );
}

/**
 * 获取用户财务管理数据
 */
export async function getWorkbenchFinanceFundsInfo() {
  return request<IDashboard.Response.FinanceManagement>(
    `${apiWorkbench}/finance/funds_info`,
  );
}

/**
 * 获取FB账户质量数据 - 整体数据
 */
export async function getWorkbenchFacebookQualityInfo() {
  return request<IDashboard.Response.FacebookQuality>(
    `${apiWorkbench}/accounts/quality_info`,
  );
}

/**
 * 获取FB账户质量数据 - 整体数据
 */
export async function getWorkbenchFacebookQualityData() {
  return request<IDashboard.Response.FacebookQuality>(
    `${apiWorkbench}/accounts/quality_data`,
  );
}

