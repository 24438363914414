import { local } from '@smb_mono/utils';
import { AllPlatform } from '@/utils/enum';
import { gtagEventBuriedPoint } from '@/utils/utils';

/** 获取当前用户登陆邮箱 */
export const Username = 'username';

/** 获取当前用户角色 */
export const userRole = 'userRole';

/** 获取当前存储的用户登陆邮箱 */
export const getUsername = () => local.get(Username);

/** 设置用户的登录邮箱 */
export const setUsername = (params?: string) => local.set(Username, params);

/** 移除用户的登录邮箱 */
export const removeUsername = () => local.remove(Username);

/**  获取当前用户角色 */
export const setuserRole = (params?: number) => local.set(userRole, params);
export const getUserRole = () => local.get(userRole);
export const removeUserRole = () => local.remove(userRole);

/** 不同媒体对应的埋点标签名称 */
export const platformMap: Record<AllPlatform, string> = {
  [AllPlatform.META]: 'FB',
  [AllPlatform.TIKTOK]: 'TT',
  [AllPlatform.SNAPCHAT]: '',
  [AllPlatform.GOOGLE]: '',
};

class Track {

  /**
   * 完整的埋点上报 包含事件名、上报属性
   * @param event_name 事件名
   * @param properties 上报属性
   */
  intractTrack(eventName: string, properties: Record<string, unknown> = {}) {
    /** 当前用户名存在，则触发埋点事件 */
    if (getUsername() && process.env.NODE_ENV !== 'development') {
      gtagEventBuriedPoint(eventName, {
        ...properties,
        Username: getUsername(),
      });
    }
  }

  /**
   * 只需传入事件名，无需上报属性的方法
   */
  simpleTrack(eventName: string) {
    this.intractTrack(eventName);
  }

  /**
   * 任意事件名，任意上报属性
   */
  static track(eventName: string, properties?: Record<string, unknown>) {
    gtagEventBuriedPoint(eventName, {
      ...properties,
      Username: getUsername() ?? '',
    });
  }
}

export default Track;
