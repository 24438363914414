import { useState } from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';

import { Locales } from '@/i18n';
import { IconFont } from '@/utils/utils';

import styles from './style.less';

const LOCAL_MAP = {
  [Locales.zhCN]: '简体(中文)',
  [Locales.enUS]: 'English',
};

const LanguageSelect = () => {
  const { i18n, t } = useTranslation();
  const [lang, setLang] = useState<string>(i18n.language);

  const handleMenuClick = ({ key }: { key: string }) => {
    setLang(key);
    i18n.changeLanguage(key);
  };

  return (
    <Menu
      className={styles.noStyle}
      mode="vertical"
      onClick={handleMenuClick}
      items={[
        {
          popupClassName: styles.subMenuWrapper,
          label: (
            <div className={styles.switchContent}>
              <div className={styles.menuTitle}>{t('language')}</div>
              <div className={styles.switch}>
                <div className={styles.lang}>{LOCAL_MAP[lang]}</div>
                <IconFont type="icon-you" className={styles.iconRight} />
              </div>
            </div>
          ),
          key: 'current_language',
          children: Object.keys(LOCAL_MAP).map((localItem) => ({
            key: localItem,
            className: styles.langItemContent,
            label: (
              <div className={styles.langItem}>
                <div className={styles.langLabel}>{LOCAL_MAP[localItem]}</div>
                {lang === localItem && (
                  <div className={styles.langChecked}>
                    <IconFont type="icon-dagou" />
                  </div>
                )}
              </div>
            ),
          })),
        },
      ]}
    />
  );
};

export default LanguageSelect;
