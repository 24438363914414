import {
  submitAccountAssignUser,
  getManagerMemberList,
  getEntityInfo,
  inviteMember,
  getSnapchatMemberList,
} from '@/services/openingRecord';
import { fetchSuccess, fetchErrorHandler } from '@/utils/utils';
// @ts-ignore add TS check, handle history code
import { SagaReturnType } from 'redux-saga/effects';
// eslint-disable-next-line @typescript-eslint/ban-types
export type State = {};

export type OpeningRecordModelType = {
  namespace: 'openingRecord';
  state: State;
  effects: {
    /** 获取BC/BM下成员列表 */
    getManagerMemberList: Effect;
    /** 获取开户详情信息 */
    getEntityInfo: Effect;
    /** 提交分配成员 */
    submitAccountAssignUser: Effect;
    /** snapchat邀请成员 */
    inviteMember: Effect;
    /** 获取Snapchat邀请成员列表 */
    getSnapchatMemberList: Effect;
  };
};

const OpeningRecordModel: OpeningRecordModelType = {
  namespace: 'openingRecord',
  state: {},
  effects: {
    *getSnapchatMemberList({ payload, onSuccess }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof getSnapchatMemberList> = yield call(
        getSnapchatMemberList,
        payload,
      );

      if (fetchSuccess(res)) {
        onSuccess?.(res);
        return;
      }

      fetchErrorHandler(res);
    },
    *getManagerMemberList({ payload, onSuccess, onFail }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof getManagerMemberList> = yield call(
        getManagerMemberList,
        payload,
      );

      if (fetchSuccess(res)) {
        onSuccess?.(res);
        return;
      }
      return onFail?.(res);
    },
    *getEntityInfo({ payload, requestSuccess }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof getEntityInfo> = yield call(
        getEntityInfo,
        payload,
      );

      if (fetchSuccess(res)) {
        return requestSuccess?.(res);
      }

      return fetchErrorHandler(res);
    },
    *submitAccountAssignUser({ payload, requestSuccess }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof submitAccountAssignUser> = yield call(
        submitAccountAssignUser,
        payload,
      );

      if (fetchSuccess(res)) {
        requestSuccess?.(res);
        return;
      }
      return fetchErrorHandler(res);
    },
    *inviteMember({ payload, onSuccess, onFail }, { call }) {
      // @ts-ignore add TS check, handle history code
      const res: SagaReturnType<typeof inviteMember> = yield call(
        inviteMember,
        payload,
      );

      if (fetchSuccess(res)) {
        onSuccess?.(res);
        return;
      }

      onFail?.(res);
      return;
    },
  },
};

export default OpeningRecordModel;
