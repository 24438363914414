import request, { ResponseStructure } from '@/utils/request';
import { API_SMB } from '@/utils/utils';
import type { CGrayInfo, GrayInfo } from '@/interface/gray';

/** 获取用户灰度控制信息 */
export async function getGrayInfo() {
  return request<ResponseStructure<GrayInfo>>(
    `${API_SMB}/user/grayscale`,
    {
      method: 'GET',
    },
  );
}

/** 获取用户roiBest灰度控制信息 */
export async function getCGrayInfo() {
  return request<ResponseStructure<CGrayInfo>>(
    `${API_SMB}/user/cgrayscale`,
    {
      method: 'GET',
    },
  );
}