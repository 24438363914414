import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './index.less';

interface IdentityCertifyTagProps {
  certified: boolean;
}

const IdentityCertifyTag: React.FC<IdentityCertifyTagProps> = ({
  certified,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={classNames(styles.certifiedWrapper, {
          [styles.certified]: certified,
          [styles.uncertified]: !certified,
        })}
      >
        <span>{certified ? t('certified') : t('uncertified')}</span>
      </div>
    </>
  );
};

export default IdentityCertifyTag;
