import { useState, Fragment, useEffect } from 'react';
import { Menu, Avatar, Dropdown } from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import classNames from 'classnames';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { history, useDispatch, useModel } from '@umijs/max';
import { EmptyText } from '@smb_mono/utils';
import { IconFont, useI18nTransform } from '@/utils/utils';

import BusinessInfo from './BusinessInfo';
import UserInfo from './UserInfo';
import ModifyPassowrd from './ModifyPassowrd';
import Logout from './Logout';
import RealNameIdentificationModal from '../RealNameIdentificationModal';
import { USER_IDENTIFY_STATUS } from '../RealNameIdentificationModal/constant';
import IdentityCertifyTag from '../RealNameIdentificationModal/IdentityCertifyTag';
import LanguageSelect from '../LanguageSelect';

import styles from './index.less';
import { useTranslation } from 'react-i18next';
import { IDashboard } from '@/pages/Dashboard/interface';
import { getUserRole } from '@/tracks';
import { BoolEnum } from '@/utils/enum';

type CurrentMenu = {
  visible: boolean;
  type?:
    | 'business-info'
    | 'user-info'
    | 'modify-passowrd'
    | 'language-switch'
    | 'to-official'
    | 'logout'
    | 'user-identification';
};

const sliceBusinessName = (businessName?: string) => {
  if (!businessName) return EmptyText;

  return businessName.length > 15
    ? `${businessName.slice(0, 15)}...`
    : businessName;
};

const AvatarDropdown: React.FC = () => {
  const [currentMenu, setCurrentMenu] = useState<CurrentMenu>({
    visible: false,
  });
  const [customerInfo, setCustomerInfo] = useState<
    Partial<IDashboard.Response.CustomerInfo>
  >({});

  const { initialState, refresh } = useModel('@@initialState');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'workDashboard/getWorkbenchCustomerInfo',
      onSuccess: (customerInfoRes: IDashboard.Response.CustomerInfo) => {
        setCustomerInfo(customerInfoRes);
      },
    });
  }, [dispatch]);

  const onMenuClick = ({ key }: MenuInfo) => {
    if (key === 'to-official') {
      history.push('/index');
    } else {
      setCurrentMenu({ visible: true, type: key as CurrentMenu['type'] });
    }
  };

  const onCancel = () => setCurrentMenu({ ...currentMenu, visible: false });

  // NOTE: label 为国际化 textId
  const menuItems: ItemType[] = [
    {
      key: 'business-info',
      icon: <IconFont type="icon-qiye" />,
      label: 'enterprise_information',
    },
    {
      key: 'user-info',
      icon: <IconFont type="icon-wode" />,
      label: 'my_information',
    },
    ...(initialState?.grayInfo?.real_name_authentication
      ? [
          {
            key: 'user-identification',
            icon: <IconFont type="icon-shenpiren" />,
            label: (
              <div className={styles.identification}>
                <div className={styles.text}>
                  {t('real_name_authentication')}
                </div>
                <IdentityCertifyTag
                  certified={
                    initialState.currentUser?.real_name_authentication ===
                    USER_IDENTIFY_STATUS.CERTIFIED
                  }
                />
              </div>
            ),
          },
        ]
      : []),
    {
      key: 'modify-passowrd',
      icon: <IconFont type="icon-mima" />,
      label: 'change_password',
    },
    {
      key: 'language-switch',
      icon: <IconFont type="icon-yuyan" />,
      label: <LanguageSelect />,
    },
    {
      type: 'divider',
    },
    {
      key: 'to-official',
      icon: <IconFont type="icon-guanwang" />,
      label: 'visit_the_official_website',
    },
    {
      key: 'logout',
      icon: <IconFont type="icon-tuichu" />,
      label: 'quit',
    },
  ];

  const menuHeaderDropdown = (
    <Menu
      className={styles.menu}
      onClick={onMenuClick}
      items={useI18nTransform(menuItems, 'label')}
    />
  );

  const renderModal = () => {
    if (currentMenu.type) {
      const Component: {
        (props: {
          visible: boolean;
          onCancel: () => void;
          onIdentifySuccess: () => void;
        }): JSX.Element | null;
      } = {
        'business-info': BusinessInfo,
        'user-info': UserInfo,
        'user-identification': RealNameIdentificationModal,
        'modify-passowrd': ModifyPassowrd,
        'language-switch': () => null,
        logout: Logout,
        'to-official': () => null,
      }[currentMenu.type];

      return (
        <Component
          visible={currentMenu.visible}
          onCancel={onCancel}
          onIdentifySuccess={() => {
            refresh();
          }}
        />
      );
    }

    return null;
  };

  return (
    <Fragment>
      <Dropdown
        placement="bottomRight"
        overlayStyle={{ minWidth: 260 }}
        overlay={menuHeaderDropdown}
      >
        <div className={classNames('fx-v-c', styles.user, 'c-p')}>
          <Avatar className="fz-16">
            {initialState?.currentUser?.nickname?.slice(0, 1)}
          </Avatar>
          <div className={styles.info}>
            <div className="f-w-5">
              {sliceBusinessName(
                initialState?.businessInfo?.business_name || EmptyText,
              )}
            </div>
            <div className={classNames('fz-12 fx-v-c', styles.rest)}>
              {getUserRole() === BoolEnum.YES ? (
                <>
                  <span>
                    {initialState?.currentUser?.nickname || EmptyText}
                  </span>
                  <span className={styles.divider} />
                  <span>{t('administrator')}</span>
                </>
              ) : (
                <span>{customerInfo?.email || EmptyText}</span>
              )}
            </div>
          </div>
        </div>
      </Dropdown>
      {renderModal()}
    </Fragment>
  );
};

export default AvatarDropdown;
