import useMergedState from 'rc-util/lib/hooks/useMergedState';
import type { MenuDataItem } from './typings';

type Dispatch<A> = (value: A) => void;

export function useMountMergeState<S>(
  initialState: S | (() => S),
  option?: {
    defaultValue?: S;
    value?: S;
    onChange?: (value: S, prevValue: S) => void;
    postState?: (value: S) => S;
  },
): [S, Dispatch<S>] {
  const [state, setState] = useMergedState<S>(initialState, option);
  return [state, setState];
}

/** 判断是否是图片链接 */
export function isImg(path: string): boolean {
  return /\w.(png|jpg|jpeg|svg|webp|gif|bmp)$/i.test(path);
}

/** 判断是否是 URL */
export const isUrl = (path: string | undefined): boolean => {
  if (!path) return false;
  if (!path.startsWith('http')) {
    return false;
  }
  try {
    const url = new URL(path);
    return !!url;
  } catch (error) {
    return false;
  }
};

export const getOpenKeysFromMenuData = (menuData?: MenuDataItem[]) => {
  return (menuData || []).reduce((pre, item) => {
    if (item.key) {
      pre.push(item.key);
    }
    if (item.routes) {
      const newArray: string[] = pre.concat(
        getOpenKeysFromMenuData(item.routes) || [],
      );
      return newArray;
    }
    return pre;
  }, [] as string[]);
};
