import { Locales } from "@/i18n";
import { useTranslation } from "react-i18next";

const useTranslationCls = () => {
  const { i18n } = useTranslation();

  return i18n.language === Locales.zhCN ? 'HelloFont-WenYiHei' : 'Roboto-Bold'
}

export default useTranslationCls;
