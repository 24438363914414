import React, { isValidElement, memo } from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';
import styles from './index.less';
import { useTranslation } from 'react-i18next';
import { currency } from '@smb_mono/utils';

const { Text } = Typography;
const amountClass = classNames('fz-16', 'f-w-5', styles.amount);
const textClass = classNames('f-w-5', styles.text);

export type XDescriptionConfigType = {
  key: React.Key;
  label?: React.ReactNode;
  value?: React.ReactNode;
  /** label开启是否溢出隐藏 */
  labelEllipsis?: boolean;
  /** value 是否溢出隐藏 如果传入的是ReactNode则此属性无效 */
  ellipsis?: boolean;
  /** value 是否使用金额格式 */
  amountFormat?: boolean;
  /** value 是否使用金额显示并添加符号并 */
  moneyFormat?: boolean;
  /** 文本是否以警告色展示 */
  warning?: boolean;
  /** 文本是否以错误色展示 */
  error?: boolean;
  /** 文本是否以信息色展示 */
  info?: boolean;
};

export type XDescriptionProps = {
  title?: React.ReactNode;
  config: XDescriptionConfigType[];
  /** 设置描述列表样式 */
  className?: string;
  /** 设置行间距 */
  rowSpace?: number;
  /** 设置每行的样式 */
  rowClassName?: string;
};

const XDescription: React.FC<XDescriptionProps> = ({
  config,
  title,
  className,
  rowSpace,
  rowClassName,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.xDescription, className)}>
      {title && (
        <div className={classNames(styles.title, 'f-w-5')}>{title}</div>
      )}
      <ul>
        {config.map((item) => (
          <li
            key={item.key}
            className={classNames(
              'fx-h-b',
              'fx-v-c',
              styles.line,
              rowClassName,
            )}
            style={{ marginBottom: rowSpace ? `${rowSpace}px` : '20px' }}
          >
            {item.label && (
              <Text
                className={styles.label}
                ellipsis={{ tooltip: item.labelEllipsis || false }}
              >
                {typeof item.label === 'string' ? t(item.label) : item.label}
              </Text>
            )}

            {isValidElement(item.value)
              ? item.value
              : item.value && (
                  <Text
                    className={classNames({
                      [amountClass]: item.amountFormat || item.moneyFormat,
                      [textClass]: !item.amountFormat,
                      [styles.warning]: item.warning,
                      [styles.error]: item.error,
                      [styles.info]: item.info,
                    })}
                    ellipsis={{ tooltip: item.ellipsis }}
                  >
                    {item.moneyFormat
                      ? `$${currency(item.value)}`
                      : item.value}
                  </Text>
                )}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default memo(XDescription);
