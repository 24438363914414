import { memo } from 'react';
import classNames from 'classnames';
import styles from './index.less';

const AssetTag: React.FC<{ total: number }> = (props) => {
  const { total } = props;

  return (
    <section className={classNames(styles.assetTag)}>
      <div className={classNames(styles.mask)}>
        <span className={styles.total}>+{total}</span>
      </div>
    </section>
  );
};

export default memo(AssetTag);
