import { useState } from 'react';
import { Button } from 'antd';

const Count: React.FC = () => {
  const [count, setCount] = useState(0);

  return (
    <div>
      <Button onClick={() => setCount(count + 1)}>增加</Button>
      <span>{count}</span>
      <Button onClick={() => setCount(count - 1)}>减少</Button>
    </div>
  );
};

export default Count;
