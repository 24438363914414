import { request as umiRequest, history } from '@umijs/max';
import { customAlphabet } from 'nanoid';
import type { Request } from '@/.umi/plugin-request/request';
import type { RequestConfig, AxiosError } from '@umijs/max';
import { errorMessage } from '@/utils/config';
import { defaultError } from '@smb_mono/utils';
import { removeUserRole, removeUsername } from '@/tracks';
import i18n, { LANGUAGE_FLAG_MAP, Locales } from '@/i18n';
import { getDvaConfig } from '@/pages/MediaApplyForm/utils';
import { homePages, noLoginPages } from './constant';
import { encrypt } from '@bluevision/tools';

const { t } = i18n;

const codeMessage = {
  200: 'the_server_successfully_returned_the_requested_data',
  201: 'successfully_created_or_modified_data',
  202: 'a_request_has_entered_the_backend_queue_asynchronous_task',
  204: 'successfully_deleted_data',
  400: 'the_request_sent_has_an_error_and_the_server_did_not_perform_any_new_or_modified_data_operations',
  401: 'the_user_does_not_have_permission_incorrect_token_username_password',
  403: 'the_user_is_authorized_but_access_is_prohibited',
  404: 'the_request_was_made_for_a_nonexistent_record_and_the_server_did_not_take_any_action',
  406: 'the_requested_format_is_not_available',
  410: 'the_requested_resource_has_been_permanently_deleted_and_will_no_longer_be_obtained',
  422: 'a_validation_error_occurred_when_creating_an_object',
  500: 'the_server_encountered_an_error_please_check_the_server',
  502: 'gateway_error',
  503: 'service_unavailable_server_temporarily_overloaded_or_under_maintenance',
  504: 'network_abnormality_please_refresh_and_try_again_later',
};

const nanoid = customAlphabet('1234567890', 6);

// 与后端约定的响应数据格式
export interface ResponseStructure<T = unknown> {
  /*
    新版本: '000000': 请求成功，其他: 请求失败
    老版本: 1:        请求成功，1:   请求失败
  */
  code: number | string;
  /** 返回码 0: 请求失败 1: 请求成功 */
  success: 0 | 1;
  /** 返回的数据 */
  data: T;
  /** 返回的提示信息 */
  info: string;
  /** 返回的唯一请求ID */
  request_id: string;
}

const publicKey = `-----BEGIN RSA PUBLIC KEY-----
MIIBCgKCAQEA0I7j9RrPP9tqoagcYv0UI7sWe5WBZqDrpl+Mk8mpBeVReLK9tqf6
zaHZqkp0kcPsmWqs2OAq+A18yZnuwje8xDrAp1ybUm4NdYdZxNzkQ/ovg4sEDWH3
gafzCRfmCyqdq54pqAx8aWcVsj3WtT00eMwhsjORavpl2SgaFVbtgePzt+SxuHzk
QYq0UgKLhQoyO6smh5AYTVuJYix2yz0ms+ENLwaYGBexCDs8N81y7u5xHlBcnFVj
fuhiavi0vWGPNx+t0WaJM4iHv3zdLD8m9rMvwATP/1jPKicA1YlGcJ0088zuiM08
fi9cYB+fZw9JaY+LVJmhojOMR/kBrRjdkQIDAQAB
-----END RSA PUBLIC KEY-----`;

// 时间戳+随机数
const getClientFetchId = () => {
  const clientFetchId = encrypt(
    Date.now() + Math.random().toString(16).slice(2),
    publicKey,
  );
  return !!clientFetchId ? clientFetchId : '';
};

/**
 * @name http 请求处理
 * @doc https://umijs.org/docs/max/request#配置
 */
export const requestConfig: RequestConfig = {
  withCredentials: true,
  timeout: 1000 * 60 * 5,
  // 错误处理： umi@3 的错误处理方案。
  errorConfig: {
    // 错误接收及处理
    errorHandler: (error, opts) => {
      // 如果 request 中手动调过错误处理
      if (opts?.skipErrorHandler) throw error;

      const { response } = error as AxiosError;

      // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
      // Axios 的错误
      if (response) {
        const { status, data, statusText } = response;

        const { info } = data as ResponseStructure;
        const msg =
          info || t(codeMessage[status]) || statusText || defaultError;

        // 登录
        if (status === 401) {
          removeUsername();
          removeUserRole();

          // 不需要登录的页面 不跳转
          if (noLoginPages.includes(location.pathname)) return;

          // 跳到登录页面
          history.push('/user/login');
          return;
        }

        // 官网页面不报错
        if (homePages.includes(location.pathname)) {
          return;
        }

        errorMessage(msg);
      } else {
        // 官网页面不报错
        if (homePages.includes(location.pathname)) {
          return;
        }

        // 网络异常
        errorMessage(t('network_abnormality'));
      }
    },
  },

  // 请求拦截器
  requestInterceptors: [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (config: any) => {
      config.headers['Smb-Request-Id'] = `${Date.now()}${nanoid()}`;
      config.headers['Smb-Request-Host'] = 'luban-plus.bluemediagroup.cn';
      config.headers['Accept-Language'] =
        LANGUAGE_FLAG_MAP[i18n.language as Locales];
      config.headers['Client-Fetch-Id'] = getClientFetchId();
      return config;
    },
  ],

  // 响应拦截器
  responseInterceptors: [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (response: any) => {
      const dispatch = getDvaConfig()?.dispatch;
      const getUmiState = getDvaConfig()?.getState;

      const handle = () => {
        if (
          dispatch &&
          getUmiState &&
          !noLoginPages.includes(location.pathname)
        ) {
          const { count } = getUmiState().requestCount;

          dispatch({
            type: 'requestCount/saveState',
            payload: { count: count + 1 },
          });

          if (count === 0) {
            errorMessage(
              response.data?.info || t('the_user_is_not_on_the_grayscale_list'),
            );
          }
        }
      };

      switch (response?.data?.code) {
        case 400012:
        case '400012':
          // 用户未同意协议
          if (dispatch && !noLoginPages.includes(location.pathname)) {
            dispatch({
              type: 'agreeProtocol/saveState',
              payload: { is_agree: false },
            });
          }

          return {
            ...response,
            // 为了不弹报错窗 伪装成成功的响应
            data: {
              ...response?.data,
              code: '000000',
            },
          };
        case 400013:
        case '400013':
          // 用户不在灰度名单
          handle();
          return {
            ...response,
            // 为了不重复弹报错窗 伪装成成功的响应
            data: {
              ...response?.data,
              code: '000000',
            },
          };
        default:
          return response;
      }
    },
  ],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const request: Request = async (url: string, opts: any = { method: 'GET' }) => {
  return umiRequest(url, opts)
    .then((res) => res)
    .catch((error) => {
      return error;
    });
};

export default request;
