import request from '@/utils/request';
// @ts-ignore add TS check, handle history code
import { API } from '@/services/typings';
import type { ResponseStructure } from '@/utils/request';
import type { WalletInfo } from '@/models/useWallet';
import type {
  WalletOrderRecordParams,
  WalletOrderDetailParams,
  WalletRechargeParams,
  IWallet,
} from '@/pages/Wallet/data.d';
import { IRechargeRecord } from '@/pages/RechargeRecord/interface';
import { ITransferRecord } from '@/pages/TransferRecord/interface';
import { API_SMB } from '@/utils/utils';
// const API_SMB = '';

/** 获取钱包信息 */
export async function getWalletInfo() {
  return request<ResponseStructure<WalletInfo>>(
    `${API_SMB}/capital/wallet_info`,
  );
}

/** 获取钱包订单记录 */
export async function getWalletOrderRecord(data: WalletOrderRecordParams) {
  return request(`${API_SMB}/capital/wallet_transaction_record`, {
    method: 'POST',
    data,
  });
}

/** 获取钱包金额变动明细 */
export async function getWalletOrderDetail(data: WalletOrderDetailParams) {
  return request(`${API_SMB}/capital/wallet_transaction_detail`, {
    method: 'POST',
    data,
  });
}

/** 获取汇率 */
export async function getExchangeRate() {
  return request<ResponseStructure<API.ExchangeRate>>(
    `${API_SMB}/capital/exchange_rate`,
  );
}

/** 获取账户充值信息 */
export async function getRechargeList(
  data: IRechargeRecord.Request.RechargeListParams,
) {
  return request<ResponseStructure<IRechargeRecord.Response.RechargeList>>(
    `${API_SMB}/capital/account_recharge_record`,
    {
      method: 'POST',
      data,
    },
  );
}

/** 获取转账信息 */
export async function getTransferList(
  data: ITransferRecord.Request.TransferListParams,
) {
  return request<ResponseStructure<ITransferRecord.Response.RechargeList>>(
    `${API_SMB}/capital/account_transfer_record`,
    {
      method: 'POST',
      data,
    },
  );
}

/** 获取 payoneer 账户信息 */
export async function getPayoneerUserInfo() {
  return request(`${API_SMB}/capital/payoneer_user_info`);
}

/** 获取 payoneer 授权链接 */
export async function getPayoneerOauthUrl() {
  return request(`${API_SMB}/capital/payoneer_oauth_url`);
}

/** 皮卡下单 */
export async function payoneerDebit(data: WalletRechargeParams) {
  return request(`${API_SMB}/capital/payoneer_recharge_wallet`, {
    method: 'POST',
    data,
  });
}

/** 皮卡确认支付 */
export async function payoneerConfirm(data: { order_num: string }) {
  return request(`${API_SMB}/capital/payoneer_commit`, {
    method: 'POST',
    data,
  });
}

/** PingPong 下单 */
export async function pingPongDebit(data: IWallet.Request.PingPongDebit) {
  return request<ResponseStructure<IWallet.Response.PingPongDebit>>(
    `${API_SMB}/capital/pp_order_create`,
    {
      method: 'POST',
      data,
    },
  );
}

/** 易宝下单 */
export async function rechargeWallet(data: WalletRechargeParams) {
  return request(`${API_SMB}/capital/cny_recharge_wallet`, {
    method: 'POST',
    data,
  });
}

/** 获取 PingPong VA 对公账户 */
export async function getPPVaAccount() {
  return request<ResponseStructure<IWallet.Response.PPVaAccount>>(
    `${API_SMB}/capital/ppva_account`,
    {
      method: 'get',
    },
  );
}
