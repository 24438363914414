import customAccesses from '@/utils/menuAccesses';
import { useModel } from '@umijs/max';
import { getFlatMenus } from '@umijs/route-utils';
import { useEffect, useMemo } from 'react';
import MenuCounter from './Counter';
import type { PrivateSiderMenuProps, SiderMenuProps } from './SiderMenu';
import SiderMenu from './SiderMenu';

const SiderMenuWrapper: React.FC<SiderMenuProps & PrivateSiderMenuProps> = (
  props,
) => {
  const { menuData: originMenuData, matchMenuKeys } = props;
  const { setFlatMenuKeys } = MenuCounter.useContainer();
  const { initialState } = useModel('@@initialState')

  const user = initialState?.currentUser;

  /** 对于总菜单做一次自定义权限的过滤（!!!目前仅支持一级菜单，可自行扩展） */
  const menuData = useMemo(() => {
    const washedMenuData = (originMenuData || []).filter(item => {
      if (item?.customAccess && user) {
        // 如果路由中配置了 customAccess 字段，去做自定义权限的过滤
        return customAccesses(user)?.[item?.customAccess]();
      }
      return true;
    })
    return washedMenuData;
  }, [originMenuData, user]);

  useEffect(() => {
    if (!menuData || menuData.length < 1) {
      return;
    }

    // 当 menu data 改变的时候重新计算这两个参数
    const newFlatMenus = getFlatMenus(menuData);
    setFlatMenuKeys(Object.keys(newFlatMenus));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchMenuKeys.join('-')]);

  return <SiderMenu {...props} menuData={menuData} />;
};

export default SiderMenuWrapper;
