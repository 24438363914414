import { memo, useState, Fragment } from 'react';
import { history } from '@umijs/max';
import { Form, Input, Button } from 'antd';
import classNames from 'classnames';
import { useRequest } from 'ahooks';
import XModal from '@/components/XModal';
import { modifyPassword } from '@/services/user';
import { IconFont, fetchSuccess } from '@/utils/utils';
import { errorMessage } from '@/utils/config';
// @ts-ignore add TS check, handle history code
import { trimSpace } from '@smb_mono/utils';
import styles from './index.less';
import { useTranslation } from 'react-i18next';

type ModifyPassowrdProps = {
  visible: boolean;
  onCancel: () => void;
};

export type Password = {
  /** 旧密码 */
  cur_password?: string;
  /** 新密码 */
  password?: string;
  /** 确认密码 */
  re_password?: string;
};

const ModifyPassowrd: React.FC<ModifyPassowrdProps> = ({
  visible,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const { loading, runAsync } = useRequest(modifyPassword, { manual: true });

  const handleSubmit = async (value: Password) => {
    try {
      const res = await runAsync(value);

      if (fetchSuccess(res)) {
        setSuccess(true);
      }

      if (!fetchSuccess(res)) {
        errorMessage(res.info || t('modification_failed_please_try_again'));
      }
    } catch (e) {}
  };

  const relogin = () => {
    history.push('/user/login');
  };

  return (
    <XModal
      open={visible}
      onCancel={onCancel}
      title={success ? '' : t('change_password')}
      centered
      footer={null}
      titleStyle={{ marginBottom: 8 }}
      className={styles.modifyPassowrd}
      maskClosable={false}
      bodyStyle={success ? { padding: '64px 0' } : {}}
    >
      {success ? (
        <div className={styles.success}>
          <IconFont type="icon-gou" />
          <div className={classNames('fz-24', styles.title)}>{t('password_modification_successful')}</div>
          <div className={styles.desc}>{t('login_to_the_backend_to_start_using_the_service')}</div>
          <Button type="primary" onClick={relogin}>
            {t('log_in_again')}
          </Button>
        </div>
      ) : (
        <Fragment>
          <div className={styles.tips}>{t('please_verify_your_old_password_before_changing_it')}</div>
          <Form
            requiredMark={false}
            layout="vertical"
            className="userForm"
            onFinish={handleSubmit}
          >
            <Form.Item
              label={t('old_password')}
              name="cur_password"
              rules={[{ required: true, message: t('please_enter_your_old_password') }]}
              getValueFromEvent={trimSpace}
            >
              <Input.Password
                size="large"
                placeholder={t('please_enter_your_old_password')}
                allowClear
              />
            </Form.Item>
            <Form.Item
              label={t('new_password')}
              name="password"
              rules={[{ required: true, message: t('please_enter_a_new_password') }]}
              getValueFromEvent={trimSpace}
            >
              <Input.Password
                size="large"
                placeholder={t('please_enter_a_new_password')}
                allowClear
              />
            </Form.Item>
            <Form.Item
              label={t('confirm_new_password')}
              name="re_password"
              dependencies={['password']}
              getValueFromEvent={trimSpace}
              rules={[
                { required: true, message: t('please_enter_the_new_password_again') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(t('the_two_passwords_you_entered_do_not_match')));
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                placeholder={t('please_enter_the_new_password_again')}
                allowClear
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
              className={styles.confirm}
            >
              {t('confirm')}
            </Button>
          </Form>
        </Fragment>
      )}
    </XModal>
  );
};

export default memo(ModifyPassowrd);
