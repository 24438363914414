import { isProdDomain } from './common';

type AccessFunc = () => boolean;

interface MenuAccess {
  [path: string]: AccessFunc
}

type MenuAccessesFunc = (user?: API.UserInfo) => MenuAccess;

/** 自定义菜单权限 */
const customAccesses: MenuAccessesFunc = (user) => {
  const offlineJakeUser = !isProdDomain() && user?.username === 'jake@sodads.com';
  const onlineAnranUser = isProdDomain() && user?.username === 'anran.zhao@bluefocus.com';

  return {
    /** 鲁班 gpt */
    lubanGptGroup: () => {
      // 线下 jake@sodads.com 或者线上 anran.zhao@bluefocus.com 不可见
      if (offlineJakeUser || onlineAnranUser) {
        return false;
      }
      // 其余用户可见
      return true;
    },
    /** 内容宇宙 gpt */
    contentUniverse: () => {
      // 线下 jake@sodads.com 或者线上 anran.zhao@bluefocus.com 可见
      if (offlineJakeUser || onlineAnranUser) {
        return true;
      }
      // 其余用户不可见
      return false;
    },
  }
}

export default customAccesses;