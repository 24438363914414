/* eslint-disable no-useless-escape */
const matchPattern = (checkValue: string = '', reg: RegExp) =>
  checkValue.match(reg);

/** 是否是有效的邮箱 */
export const isEmail = (email: string) =>
  matchPattern(
    email,
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

export const isUrl = (path: string) =>
  matchPattern(
    path,
    /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g,
  );

export const isShopifyStore = (str: string) =>
  matchPattern(str, /^(https?:\/\/)?[A-Za-z0-9.-]+\.myshopify\.com/);

export const isHttpLink = (path: string) =>
  matchPattern(
    path,
    /^(https?):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/,
  );

export const isPhone = (phone: string) => {
  const regPhone = /^(1[0-9]{2})\d{8}$/;
  // const regFixed = /(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}/; // 固话
  const regFixed = /^0\d{2,3}-?\d{7,8}$/;
  return matchPattern(phone, regPhone) || matchPattern(phone, regFixed);
};

export const isMobilePhone = (phone: string) =>
  matchPattern(phone, /^(1[0-9]{2})\d{8}$/);

export const isCaptchaCode = (val: string) =>
  typeof Number(val.trim()) === 'number' &&
  Number(val.trim()).toString() !== 'NaN';

export const isFixedPhone = (phone: string) =>
  matchPattern(phone, /^0\d{2,3}-?\d{7,8}$/);

export const isQQ = (qq: string) => matchPattern(qq, /^[1-9][0-9]{4,10}$/);

// 排除全部为英文的字符串
export const isCN = (c: string) => !matchPattern(c, /^[\w\s]{0,}$/);

export const isAllCN = (c: string) => matchPattern(c, /^[\u4e00-\u9fa5\s]+$/);

export const isEN = (c: string) => matchPattern(c, /^[^\u4e00-\u9fa5]{0,}$/);

export const isRegisteredNum = (c: string) =>
  matchPattern(
    c,
    /(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/,
  );

export const isIdCard = (c: string) =>
  matchPattern(c, /^(\d{18,18}|\d{15,15}|\d{17,17}[xX]{1})$/);

export const isBankCard = (c: string) => matchPattern(c, /^[1-9]\d{15,20}$/);

export const isElementInViewport = (el: HTMLBodyElement) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const isWebsite = (c: string) =>
  matchPattern(
    c,
    /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/,
  );

/**
 * 将 url 中的路径去掉，只保留域名
 * @param {*} url url
 */
export const extractDomainName = (url: string) => {
  const reg =
    /((http:\/\/)|(https:\/\/))?([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}/;
  return (reg.exec(url) || [])[0] || '';
};
