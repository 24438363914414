type Count = {
  count: number;
}

export interface RequestCountType {
  namespace: 'requestCount';
  state: Count;
  reducers: {
    saveState: Reducer<Count>;
  };
}
const defaultState: Count = {
  count: 0,
};

const RequestCount: RequestCountType = {
  namespace: 'requestCount',
  state: defaultState,
  reducers: {
    saveState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default RequestCount;
