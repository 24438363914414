import { useState } from 'react';

export default () => {
  const [currentStep, setStep] = useState<number>(0);

  return {
    currentStep,
    setStep,
  };
};
