import { useState } from 'react';
import { useRequest } from 'ahooks';
import { getWalletInfo as fetchWalletInfo } from '@/services/wallet';
import type { SettlementType } from '@/utils/enum';

export type WalletInfo = {
  /** 钱包 id */
  wallet_id?: string;
  /** 钱包状态，0：禁用，1：正常 */
  wallet_status?: 0 | 1;
  /** 钱包余额（单位：美元） */
  wallet_balance?: string;
  /** 企业额度（单位：美元） */
  advance_quota?: string;
  /** 结算方式，1：预付 2：额度预付 */
  settlement_type?: SettlementType;
};

export default () => {
  const [walletInfo, setWalletInfo] = useState<WalletInfo>({});

  const { loading, run: getWalletInfo } = useRequest(fetchWalletInfo, {
    manual: true,
    onSuccess(res) {
      setWalletInfo(res.data || {});
    },
    onError() {
      setWalletInfo({});
    },
  });

  return {
    /** 是否正在加载钱包信息中 */
    loading,
    /** 钱包信息 */
    walletInfo,
    /** 获取钱包信息
     *
     * 注意: 这是一个同步方法, 不能在函数组件中直接调用
     *
     * 你应该在 useEffect 或者 事件函数中调用
     */
    getWalletInfo,
  };
};
