import { useState } from 'react';
import { useRequest } from 'ahooks';
import { getPageQualityInfo } from '@/services/policy';
import { IPolicy } from '@/pages/Policy/type';

const defaultInfo = {
  last14d_disapproved_page_number: 0,
  last7d_disapproved_page_number: 0,
  disabled_page_number: 0,
  page_number: 0,
  active_page_rate: 0,
};

export default () => {
  const [info, setInfo] =
    useState<IPolicy.Response.PolicyQualityInfo>(defaultInfo);

  const { loading, run: getPolicyInfo } = useRequest(getPageQualityInfo, {
    manual: true,
    onSuccess(res) {
      setInfo(res.data);
    },
    onError() {
      setInfo(defaultInfo);
    },
  });

  return {
    loading,
    info,
    getPolicyInfo,
  };
};
