import request, { ResponseStructure } from '@/utils/request';
import type {
  WalletRechargeAccountsParams,
  ConfirmRechargeParams,
  AccountCapitalParams,
  CreateAccountTransferOrderParams,
  AccountListModule,
} from '@/pages/AccountList/interface';
import { AllPlatform } from '@/utils/enum';
import { API_SMB } from '@/utils/utils';
import {
  TagType
} from '@/pages/AccountList/interface';

// const API_SMB = '';

/** 获取广告账户列表 */
export async function getAccountList(
  data: AccountListModule.Request.GetAccountList,
) {
  return request<ResponseStructure<AccountListModule.Response.GetAccountList>>(
    `${API_SMB}/ad_account/get_account_list`,
    {
      method: 'POST',
      data,
    },
  );
}

/** 获取账户的资金详情 */
export async function getAccountCapitalInfo(data: AccountCapitalParams) {
  return request(`${API_SMB}/capital/account_capital_info`, {
    method: 'POST',
    data,
  });
}

/** 可转账的广告账户列表 */
export async function getTransferAccount(
  data: AccountListModule.Request.GetTransferAccount,
) {
  return request<
    ResponseStructure<AccountListModule.Response.GetTransferAccount>
  >(`${API_SMB}/capital/can_recharge_accounts`, {
    method: 'POST',
    data,
  });
}

/** 账户转账下单 创建订单 */
export async function createAccountTransferOrder(
  data: CreateAccountTransferOrderParams,
) {
  return request(`${API_SMB}/capital/account_transfer_order`, {
    method: 'POST',
    data,
  });
}

/** 账户转账 确认下单 */
export async function confirmTransferOrder(data: {
  order_id: number;
  platform: AllPlatform;
}) {
  return request(`${API_SMB}/capital/account_transfer_confirm`, {
    method: 'POST',
    data,
  });
}

/** 钱包充值账户 创建订单的过程 */
export async function walletRechargeAccounts(
  data: WalletRechargeAccountsParams,
) {
  return request(`${API_SMB}/capital/wallet_recharge_accounts`, {
    method: 'POST',
    data,
  });
}

/** 充值订单确认 提交订单 */
export async function confirmRecharge(data: ConfirmRechargeParams) {
  return request(`${API_SMB}/capital/recharge_confirm`, {
    method: 'POST',
    data,
  });
}

/** 获取账号资产信息 */
export async function getMediaAccountsInfo() {
  return request<
    ResponseStructure<AccountListModule.Response.GetMediaAccountsInfo>
  >(`${API_SMB}/capital/media_accounts_balance`);
}

/** 获取开户地址 */
export async function getOEOpenUrl() {
  return request(`${API_SMB}/entity/oe_open_url`);
}

/** 获取用户授权下的全量商务管理信息 */
export async function getMediaManageList(
  data: AccountListModule.Request.GetMediaManageList,
) {
  return request<
    ResponseStructure<AccountListModule.Response.GetMediaManageList>
  >(`${API_SMB}/ad_account/get_media_manager_list`, {
    data,
    method: 'POST',
  });
}

/** 获取账户已绑定的信息-BM/BC/MCC
 *  用户维度，仅筛选出用户下所绑定的商务信息
 */
export async function getAccountManagerList(
  data: AccountListModule.Request.GetAccountManagerList,
) {
  return request<
    ResponseStructure<AccountListModule.Response.AccountManagerListItem[]>
  >(`${API_SMB}/ad_account/account_manager`, {
    data,
    method: 'POST',
  });
}

/** 获取账户已绑定的信息 - BM/BC/MCC
 *  账户维度，不关注当前用户是否有绑定的信息
 *  应用场景：绑定BM、绑定BC查询账户下的信息
 *  请求参数同getAccountManagerList相同
 */
export async function getAllAccountManagerList(
  data: AccountListModule.Request.GetAccountManagerList,
) {
  return request<
    ResponseStructure<AccountListModule.Response.AccountManagerListItem[]>
  >(`${API_SMB}/ad_account/account_all_manager`, {
    data,
    method: 'POST',
  });
}

/** 绑定商务管理 */
export async function submitBindManager(
  data: AccountListModule.Request.SubmitBindManagerParams,
) {
  return request<
    ResponseStructure<AccountListModule.Response.SubmitBindManagerResult>
  >(`${API_SMB}/ad_account/manager_bind`, {
    data,
    method: 'POST',
  });
}

/** 解绑商务管理 */
export async function submitRemoveManager(
  data: AccountListModule.Request.SubmitBindManagerParams,
) {
  return request<
    ResponseStructure<AccountListModule.Response.SubmitBindManagerResult>
  >(`${API_SMB}/ad_account/manager_remove`, {
    data,
    method: 'POST',
  });
}

/** 添加成员 */
export async function submitSnapchatAddMembers(
  data: AccountListModule.Request.AddMembersParams,
) {
  return request(`${API_SMB}/ad_account/snap_account_assign_user`, {
    data,
    method: 'POST',
  });
}

/** 账户重命名 */
export async function submitReName(
  data: AccountListModule.Request.ReNameParams,
) {
  return request<ResponseStructure>(`${API_SMB}/ad_account/update_account_name`, {
    data,
    method: 'POST',
  });
}

/** 获取媒体标签 */
export async function getTags(params: {
  platform: AllPlatform;
}) {
  return request<ResponseStructure<{ platform: AllPlatform, tags: TagType[] }>>(`${API_SMB}/tag_manage/get_tags`, { params });
}


/** 关联并创建标签 */
export async function linkTag(
  data: AccountListModule.Request.LinkTagParams,
) {
  return request<ResponseStructure<{ tags: TagType[] }>>(`${API_SMB}/tag_manage/link_account`, {
    data,
    method: 'POST',
  });
}

/** 取关标签 */
export async function unlinkTag(
  data: AccountListModule.Request.UnlinkTagParams,
) {
  return request<ResponseStructure>(`${API_SMB}/tag_manage/unlink_account`, {
    data,
    method: 'POST',
  });
}

