/** 结算方式 */
export const enum SettlementType {
  /** 预付 */
  Prepaid = 1,
  /** 额度预付 */
  QuotaPrepaid,
}

/** 所有支付方式的枚举值 */
export const enum PayType {
  /** 支付宝 */
  Alipay = 1,
  /** 微信 */
  WX = 2,
  /** 信用卡 */
  CreditCard = 3,
  /** 银联 */
  UnionPay = 4,
  /** 快捷支付 */
  QuickPay = 5,
  /** payoneer */
  Payoneer = 6,
  /** 钱包 */
  Wallet = 7,
  /** PingPing 线上支付 */
  PingPong = 8,
  /** PingPong VA 支付 */
  PingPongVA = 9,
}

/** 支付渠道 */
export enum PAY_CHANNEL {
  CNY = 'CNY',
  PAYONEER = 'PAYONEER',
  PINGPONG = 'PINGPONG',
}

export const PAY_TYPE_CHANNEL_MAP = {
  [PayType.Alipay]: PAY_CHANNEL.CNY,
  [PayType.WX]: PAY_CHANNEL.CNY,
  [PayType.UnionPay]: PAY_CHANNEL.CNY,
  [PayType.Payoneer]: PAY_CHANNEL.PAYONEER,
  [PayType.PingPong]: PAY_CHANNEL.PINGPONG,
}
/**
 * platform 枚举值, 支持反向映射
 *
 * Platform.facebook -> 1
 *
 * Platform[1] -> facebook
 */
export enum Platform {
  facebook = 1,
  google = 2,
  shopify = 3,
  tiktok = 4,
  socialpeta = 5,
  wallet = 6,
}

/** 开户商枚举 */
export enum SmbSupplier {
  BV = 1,
  Panda = 2,
}

/** 账户筛选类型枚举 */
export const enum AccountFilterEnum {
  /** 账户ID */
  AccountID = 1,
  /** 账户名称 */
  AccountName = 2,
}

/** 步骤状态枚举 */
export const enum StepEnum {
  /** 第一步 */
  FirstStep = 1,
  SecondStep = 2,
  ThirdStep = 3,
}

/** 请求失败 */
export const enum Request {
  /** 请求失败 */
  Failed,
}

/** 媒体平台枚举值，多用于接口传参 */
export enum AllPlatform {
  META = 'META',
  TIKTOK = 'TIKTOK',
  SNAPCHAT = 'SNAPCHAT',
  GOOGLE = 'GOOGLE',
}

/** 适用于 1、0 判断 true、false 的场景 */
export enum Bool {
  YES = 1,
  NO = 0,
}

// 用户角色
// LUBAN : 鲁班用户激活 - BUSINESS：企业用户激活-未注册过鲁班 - LUBAN_BUSINESS: 企业用户激活-已注册过鲁班
export enum UserRole {
  LUBAN = 'LUBAN',
  BUSINESS = 'BUSINESS',
  LUBAN_BUSINESS = 'LUBAN_BUSINESS',
}

/** 适用于 1、0 判断 true、false 的场景 */
export enum BoolEnum {
  YES = 'YES',
  NO = 'NO',
}
